@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Quattrocento:wght@400;700&display=swap");
body {
  color: #484848;
  font-family: "YuMincho", "Yu Mincho", "游明朝", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", serif;
  font-weight: 400;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.6em;
}

a img {
  opacity: 1;
  transition: opacity 0.4s;
}
a:hover img {
  opacity: 0.8;
}
a.opacity {
  opacity: 1;
  transition: opacity 0.4s;
}
a.opacity:hover {
  opacity: 0.8;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.center {
  text-align: center;
}

.flex {
  display: flex;
}

.f-wrap {
  flex-wrap: wrap;
}

.f-jc-sb {
  justify-content: space-between;
}

.f-jc-c {
  justify-content: center;
}

.f-jc-fe {
  justify-content: flex-end;
}

.f-ai-c {
  align-items: center;
}

.f-ai-fs {
  align-items: flex-start;
}

.f-ai-fe {
  align-items: flex-end;
}

.f-ac-c {
  align-content: center;
}

.base {
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 15px;
}
@media only screen and (max-width: 767px) {
  .base {
    padding: 0 2rem;
  }
}

@media only screen and (min-width: 768px) {
  .sp {
    display: none !important;
  }
}
@media only screen and (max-width: 767px) {
  .pc {
    display: none !important;
  }
}
/* ----------------------------------------

Wedding garden

---------------------------------------- */
@media only screen and (max-width: 767px) {
  :root {
    font-size: 2.6666666667vw;
  }
}

/* 基本ボックス設定
------------------------------- */
.container {
  width: 100%;
  overflow: hidden;
}

.wrap__out,
.wrap__main {
  width: 100%;
  height: auto;
}

.wrap__in {
  width: 1200px;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}
@media only screen and (max-width: 767px) {
  .wrap__in {
    width: 29.5rem;
  }
}

/*テーブルブロック
------------------------------- */
.table_block {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.table_block > .table_block--cell {
  display: table-cell;
  width: 50%;
  text-align: center;
  vertical-align: middle;
}
@media only screen and (max-width: 767px) {
  .table_block._sp_tate {
    display: block;
    table-layout: auto;
  }
  .table_block._sp_tate > .table_block--cell {
    display: block;
    width: 100%;
  }
}

/* flex box
------------------------------- */
.flex_block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

/* 画像ブロック
------------------------------- */
.img_block {
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.img_block::after {
  content: "";
  display: block;
  padding-top: 100%;
}

/* object-fit
------------------------------- */
.fit_img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/* ラインマーカー
------------------------------- */
.linemk {
  background: linear-gradient(transparent 70%, #fccb0f 70%);
}

/* ----------------------------------------

共通部分

---------------------------------------- */
/* header
------------------------------- */
.header {
  background-color: #fff;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
}
.header__in {
  display: flex;
  align-items: stretch; /* 縦方向*/
  justify-content: flex-start; /*横方向*/
  flex-wrap: wrap;
  width: 100%;
  height: 108px;
  margin: 0 auto;
  color: #7E7573;
}
@media only screen and (max-width: 767px) {
  .header__in {
    height: 7.5rem;
  }
}
.header__logo {
  display: block;
  width: 237px;
  position: relative;
  padding-top: 41px;
}
.header__logo__img {
  display: block;
  width: 202px;
  margin-left: auto;
  margin-right: 0;
}
.header__logo img {
  display: block;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .header__logo {
    flex-grow: 1;
    width: auto;
    padding-top: 2.6rem;
    padding-left: 1.98rem;
    position: relative;
  }
  .header__logo__img {
    width: 13.75rem;
    margin-left: 0;
  }
  .header__logo__insta_sp {
    display: block;
    width: 1.2rem;
    height: auto;
    position: absolute;
    top: 50%;
    right: 2.7rem;
    margin-top: -0.6rem;
  }
  .header__logo__insta_sp img {
    display: block;
    width: 100%;
  }
}
.header__ttl {
  font-family: "游ゴシック", "YuGothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.1em;
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 !important;
  border-right: 0 !important;
}
@media only screen and (max-width: 767px) {
  .header__ttl {
    display: none;
  }
}
.header__nav {
  display: block;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 35px;
}
@media only screen and (min-width: 768px) {
  .header__nav {
    flex-grow: 1;
  }
}
@media only screen and (max-width: 767px) {
  .header__nav {
    position: absolute;
    top: 7.5rem;
    right: 0;
    width: 100%;
    height: calc(100vh - 7.5rem);
    padding: 0 !important;
    background-color: #fff;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .header__nav::-webkit-scrollbar {
    display: none;
  }
  .header__nav .header__ttl {
    display: none !important;
  }
  .header__nav__inner {
    display: flex;
    align-items: flex-start; /* 縦方向*/
    justify-content: flex-end; /*横方向*/
    flex-wrap: wrap;
    flex-direction: column-reverse;
    padding: 3rem 0 12rem;
  }
}
.header__nav__in {
  display: flex;
  align-items: flex-start; /* 縦方向*/
  justify-content: flex-end; /*横方向*/
  flex-wrap: wrap;
  width: 100%;
  max-width: 910px;
  margin-left: auto;
  margin-right: 0;
}
@media only screen and (min-width: 768px) {
  .header__nav__in a {
    color: #7E7573;
    letter-spacing: 0.09em;
    line-height: 1.2;
    display: block;
  }
  .header__nav__in._main {
    justify-content: space-between; /*横方向*/
    font-size: 13px;
    margin-top: 22px;
  }
  .header__nav__in._main > li {
    height: 43px;
  }
  .header__nav__in._main > li > a {
    height: 21px;
  }
  .header__nav__in._sub {
    position: relative;
  }
  .header__nav__in._sub li {
    font-family: "游ゴシック", "YuGothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-size: 11px;
    padding: 0 0.8em;
  }
  .header__nav__in._sub li:not(.header__ttl):not(._ico) {
    position: relative;
  }
  .header__nav__in._sub li:not(.header__ttl):not(._ico)::before {
    content: "";
    display: block;
    width: 1px;
    height: 10px;
    background-color: #7E7573;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -5px;
  }
  .header__nav__in._sub li.header__ttl + li::before {
    display: none !important;
  }
  .header__nav__in._sub li._ico {
    display: flex;
    align-items: center; /* 縦方向*/
    justify-content: flex-start; /*横方向*/
    flex-wrap: wrap;
    gap: 8px;
    border-right: 0;
    padding-right: 4px;
    padding-left: 13px;
  }
  .header__nav__in._sub li._ico .ico-insta {
    display: block;
    width: 12px;
  }
  .header__nav__in._sub li._ico .ico-fb {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .header__nav__in {
    display: block;
    width: 29.4rem;
    max-width: 100%;
    margin: 0 auto;
  }
  .header__nav__in > li {
    width: 100%;
    padding: 0 !important;
    border-right: 0 !important;
  }
  .header__nav__in > li > a {
    display: flex;
    align-items: center; /* 縦方向*/
    justify-content: flex-start; /*横方向*/
    flex-wrap: wrap;
    width: 100%;
    height: 5rem !important;
    border-bottom: 1px solid #BEB292;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.1em;
    color: #9B9277;
    background-image: url("../../img/common/ico_oval_arrow_br_whB.svg");
    background-size: 2.1rem auto;
    background-position: right 0 center;
    background-repeat: no-repeat;
    background-color: #fff;
  }
  .header__nav__in > li._ico {
    display: flex;
    align-items: flex-start; /* 縦方向*/
    justify-content: flex-start; /*横方向*/
    flex-wrap: wrap;
    width: 100%;
    padding: 4rem 0 !important;
    gap: 2rem !important;
  }
  .header__nav__in > li._ico > a {
    display: block !important;
    width: auto !important;
    height: auto !important;
    background-image: none;
    border-bottom: 0 !important;
  }
  .header__nav__in > li._ico > a > img {
    width: auto;
    height: 1.8rem;
  }
  .header__nav__in > li._ico > a.ico-fb {
    display: block !important;
  }
}
.header__btn {
  display: block;
  width: 133px;
  height: 100%;
  background-color: #70905B;
  color: #fff;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.05em;
  text-align: center;
  padding-top: 30px;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .header__btn {
    display: block;
    width: 7.5rem;
    height: 100%;
    background-color: #70905B;
    color: #fff;
    font-size: 1.1rem;
    line-height: 1.5;
    letter-spacing: 0.05em;
    text-align: center;
    padding-top: 2.06rem;
  }
}
.header__btn ._ico_mail {
  display: block;
  width: 18px;
  margin: 0 auto 9px;
}
@media only screen and (max-width: 767px) {
  .header__btn ._ico_mail {
    display: block;
    width: 1.75rem;
    margin: 0 auto 0.9rem;
  }
}
@media only screen and (min-width: 768px) {
  .header__btn {
    opacity: 1;
    transition: opacity 0.4s;
  }
  .header__btn:hover {
    opacity: 0.8;
  }
}
.header__menu-btn {
  display: none;
  width: 133px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.4s;
}
.header__menu-btn:hover {
  opacity: 0.8;
}
@media only screen and (max-width: 767px) {
  .header__menu-btn {
    display: flex;
    align-items: center; /* 縦方向*/
    justify-content: center; /*横方向*/
    flex-wrap: wrap;
    width: 7.1rem;
    height: 100%;
  }
}
.header__menu-btn ._btn_menu {
  display: block;
  margin: 0 auto;
  width: 30px;
}
@media only screen and (max-width: 767px) {
  .header__menu-btn ._btn_menu {
    width: 3rem;
  }
}

/*pull down menu*/
@media only screen and (min-width: 768px) {
  .header__in {
    position: relative;
  }
}

@media (min-width: 1131px) {
  .pull-down-menu__btn {
    display: none;
  }
  .pull-down-menu__block {
    max-height: 0;
    overflow: hidden;
    width: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 0;
    transition: max-height 0.4s;
  }
  .pull-down-menu__inner {
    display: flex;
    align-items: center; /* 縦方向*/
    justify-content: center; /*横方向*/
    flex-wrap: wrap;
    min-height: 77px;
    background-color: #F4F4F4;
  }
  .pull-down-menu__list {
    display: flex;
    align-items: flex-start; /* 縦方向*/
    justify-content: flex-start; /*横方向*/
    flex-wrap: wrap;
    gap: 8px 32px;
    max-width: 90%;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.05em;
    padding: 10px 0;
  }
  .pull-down-menu__list__item a {
    display: flex;
    align-items: center; /* 縦方向*/
    justify-content: flex-start; /*横方向*/
    flex-wrap: wrap;
    min-height: 20px;
    background-image: url("../../img/common/ico_oval_arrow_gr_wh.svg");
    background-size: 17px auto;
    background-position: right 0 center;
    background-repeat: no-repeat;
    padding-right: 24px;
    color: #70905B;
  }
  .pull-down-menu__list._brown .pull-down-menu__list__item a {
    color: #9B9277;
    background-image: url("../../img/common/ico_oval_arrow_br_wh.svg");
  }
  .pull-down-menu:hover > a {
    color: #70905B;
    border-bottom: 1px solid #70905B;
  }
  .pull-down-menu:hover .pull-down-menu__block {
    max-height: 500px;
    z-index: 1;
  }
}
@media only screen and (max-width: 767px) {
  .pull-down-menu {
    position: relative;
  }
  .pull-down-menu > a {
    background-image: none !important;
  }
  .pull-down-menu__btn {
    display: block;
    width: 100%;
    height: 5rem;
    position: absolute;
    top: 0;
    right: 0;
  }
  .pull-down-menu__btn::after {
    content: "";
    display: block;
    width: 2.1rem;
    height: 2.1rem;
    background-image: url("../../img/common/ico_oval_arrow_br_wh_down.svg");
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -1.05rem;
    transition: 0.4s;
  }
  .pull-down-menu__list {
    padding: 1rem 0 2rem;
  }
  .pull-down-menu__list__item > a {
    display: flex;
    align-items: center; /* 縦方向*/
    justify-content: flex-start; /*横方向*/
    flex-wrap: wrap;
    width: 100%;
    height: 4.4rem;
    font-size: 1.3rem;
    line-height: 1.2;
    letter-spacing: 0.05em;
    text-align: left;
    color: #70905B;
    border-bottom: 1px solid #e0dcd0;
    background-image: url("../../img/common/ico_oval_arrow_gr_wh.svg");
    background-size: 1.7rem auto;
    background-position: right 0 center;
    background-repeat: no-repeat;
  }
  .pull-down-menu__list._brown .pull-down-menu__list__item > a {
    background-image: url("../../img/common/ico_oval_arrow_br_wh.svg");
    color: #9B9277;
  }
}

/*相談予約 お問い合わせ drawer*/
.drawer-contact {
  width: 100%;
  height: calc(100vh - 108px);
  position: fixed;
  top: 108px;
  left: 0;
  background-color: #70905B;
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .drawer-contact {
    width: 100%;
    height: calc(100vh - 7.5rem);
    top: 7.5rem;
    left: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .drawer-contact::-webkit-scrollbar {
    display: none;
  }
}
.drawer-contact__block {
  max-width: 1200px;
  width: 96%;
  height: 100%;
  margin: 0 auto;
  padding-top: 73px;
  padding-bottom: 10px;
}
@media only screen and (max-width: 767px) {
  .drawer-contact__block {
    max-width: 100%;
    width: 29.5rem;
    height: auto;
    padding-top: 5.7rem;
    padding-bottom: 5rem;
  }
}
.drawer-contact__inner {
  width: 100%;
  height: 100%;
}
@media only screen and (min-width: 768px) {
  .drawer-contact__inner {
    padding-bottom: 50px;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}
.drawer-contact__inner::-webkit-scrollbar {
  display: none;
}
.drawer-contact__inner > .btn-oval-arrow {
  margin: 0 auto 32px;
}
@media only screen and (max-width: 767px) {
  .drawer-contact__inner {
    height: auto;
    padding-bottom: 12rem;
  }
  .drawer-contact__inner > .btn-oval-arrow {
    width: 100%;
    margin: 0 auto 2rem;
  }
}
.drawer-contact__ttl-large {
  font-family: "Quattrocento";
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.1em;
  text-align: center;
  margin-bottom: 34px;
}
.drawer-contact__ttl-large span {
  font-family: "YuMincho", "Yu Mincho", "游明朝", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", serif;
  display: block;
  font-size: 12px;
  font-weight: 400;
  line-height: 2;
  margin-top: 10px;
  letter-spacing: 0.05em;
}
@media only screen and (max-width: 767px) {
  .drawer-contact__ttl-large {
    font-size: 2rem;
    margin-bottom: 2.3rem;
  }
  .drawer-contact__ttl-large span {
    font-size: 1.2rem;
    margin-top: 1rem;
  }
}
.drawer-contact__p {
  font-family: "YuMincho", "Yu Mincho", "游明朝", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", serif;
  font-size: 13px;
  line-height: 1.4;
  letter-spacing: 0.05em;
  text-align: center;
}
.drawer-contact__p a {
  display: block;
  font-family: "Quattrocento";
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.1em;
  color: #fff;
  margin-bottom: 2px;
}
@media only screen and (max-width: 767px) {
  .drawer-contact__p {
    font-size: 1.3rem;
  }
  .drawer-contact__p a {
    font-size: 2rem;
    margin-bottom: 0.2rem;
  }
}
.drawer-contact__ttl-mid {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.05em;
  text-align: center;
  padding-bottom: 20px;
  margin-bottom: 29px;
  border-bottom: 1px solid #fff;
}
@media (max-width: 1000px) {
  .drawer-contact__ttl-mid {
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .drawer-contact__ttl-mid {
    display: flex;
    align-items: center; /* 縦方向*/
    justify-content: flex-start; /*横方向*/
    flex-wrap: wrap;
    height: 6.2rem;
    font-size: 1.6rem !important;
    text-align: left;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .drawer-contact__ttl-mid + ._aco_block_sp {
    padding-top: 1.7rem;
  }
}
.drawer-contact__list {
  margin-bottom: 35px;
}
@media only screen and (min-width: 768px) {
  .drawer-contact__list {
    display: flex;
    align-items: flex-start; /* 縦方向*/
    justify-content: flex-start; /*横方向*/
    flex-wrap: wrap;
    gap: 20px 2.75%;
  }
}
@media only screen and (max-width: 767px) {
  .drawer-contact__list {
    margin-bottom: 5.4rem;
  }
}
.drawer-contact__list__item {
  width: calc((100% - 1px - 8.25%) / 4);
}
.drawer-contact__list__item .btn-oval-arrow {
  width: 100%;
  margin-bottom: 8px;
}
@media only screen and (max-width: 767px) {
  .drawer-contact__list__item {
    width: 100%;
  }
  .drawer-contact__list__item .btn-oval-arrow {
    margin-bottom: 0;
  }
  .drawer-contact__list__item .btn-oval-arrow + .btn-oval-arrow {
    margin-top: 0.5rem;
  }
}

.btn-oval-arrow {
  display: flex;
  align-items: center; /* 縦方向*/
  justify-content: center; /*横方向*/
  flex-wrap: wrap;
  width: 275px;
  height: 60px;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.05em;
  text-align: center;
  color: #70905B;
  background-color: #fff;
  background-image: url("../../img/common/ico_oval_arrow_gr.svg");
  background-size: 22px auto;
  background-position: right 15px center;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 767px) {
  .btn-oval-arrow {
    width: 29.5rem;
    max-width: 100%;
    height: 6rem;
    font-size: 1.3rem;
    background-size: 2.2rem auto;
    background-position: right 2rem center;
  }
}

@media only screen and (max-width: 767px) {
  ._aco-btn-sp {
    position: relative;
  }
  ._aco-btn-sp::after {
    content: "";
    display: block;
    width: 2.2rem;
    aspect-ratio: 1/1;
    background-image: url("../../img/common/ico_oval_arrow_wh_dwn.svg");
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -1.2rem;
    transition: 0.3s;
  }
  ._aco-btn-sp._active::after {
    transform: rotate(-180deg);
  }
}

@media only screen and (max-width: 767px) {
  ._aco-block-sp {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1300px) {
  .header__nav {
    padding-right: 15px;
  }
  .header__logo {
    width: 217px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1130px) {
  .header {
    /*menu*/
  }
  .header__logo {
    width: auto;
    flex-grow: 1;
  }
  .header__logo__img {
    margin-left: 20px;
    margin-right: auto;
  }
  .header__nav {
    position: absolute;
    top: 108px;
    right: 0;
    width: 375px;
    height: calc(100vh - 108px);
    padding: 0 !important;
    background-color: #fff;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .header__nav::-webkit-scrollbar {
    display: none;
  }
  .header__nav .header__ttl {
    display: none !important;
  }
  .header__nav__inner {
    display: flex;
    align-items: flex-start; /* 縦方向*/
    justify-content: flex-end; /*横方向*/
    flex-wrap: wrap;
    flex-direction: column-reverse;
    padding: 30px 0;
  }
  .header .header__nav__in {
    display: block;
    width: 294px;
    margin: 0 auto;
  }
  .header .header__nav__in > li {
    width: 100%;
    padding: 0 !important;
    border-right: 0 !important;
    height: auto;
  }
  .header .header__nav__in > li::before {
    display: none !important;
  }
  .header .header__nav__in > li > a {
    display: flex;
    align-items: center; /* 縦方向*/
    justify-content: flex-start; /*横方向*/
    flex-wrap: wrap;
    width: 100%;
    height: 50px !important;
    border-bottom: 1px solid #BEB292;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.1em;
    color: #9B9277;
    background-image: url("../../img/common/ico_oval_arrow_br_whB.svg");
    background-size: 21px auto;
    background-position: right 0 center;
    background-repeat: no-repeat;
    background-color: #fff;
  }
  .header .header__nav__in > li._ico {
    width: 100%;
    padding: 40px 0 !important;
    gap: 20px !important;
  }
  .header .header__nav__in > li._ico > a {
    display: block !important;
    width: auto !important;
    height: auto !important;
    background-image: none;
    border-bottom: 0 !important;
  }
  .header .header__nav__in > li._ico > a > img {
    width: auto;
    height: 19px;
  }
  .header .header__nav__in > li._ico > a.ico-fb {
    display: block !important;
  }
  .header__menu-btn {
    display: flex;
    align-items: center; /* 縦方向*/
    justify-content: center; /*横方向*/
    flex-wrap: wrap;
    text-align: center;
  }
  .header .pull-down-menu {
    position: relative;
  }
  .header .pull-down-menu:hover > a,
  .header .pull-down-menu:hover .pull-down-menu__btn {
    opacity: 0.8;
  }
  .header .pull-down-menu > a {
    background-image: none !important;
  }
  .header .pull-down-menu__btn {
    display: block;
    width: 100%;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0;
    cursor: pointer;
    transition: opacity 0.4s;
  }
  .header .pull-down-menu__btn::after {
    content: "";
    width: 21px;
    height: 21px;
    background-image: url("../../img/common/ico_oval_arrow_br_wh_down.svg");
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 25px;
    right: 0;
    margin-top: -10.5px;
    transition: 0.4s;
  }
  .header .pull-down-menu__list {
    padding: 10px 0;
  }
  .header .pull-down-menu__list__item > a {
    display: flex;
    align-items: center; /* 縦方向*/
    justify-content: flex-start; /*横方向*/
    flex-wrap: wrap;
    width: 100%;
    height: 44px;
    font-size: 13px;
    line-height: 1.2;
    letter-spacing: 0.05em;
    text-align: left;
    color: #70905B;
    border-bottom: 1px solid #e0dcd0;
    background-image: url("../../img/common/ico_oval_arrow_gr_wh.svg");
    background-size: 17px auto;
    background-position: right 0 center;
    background-repeat: no-repeat;
  }
  .header .pull-down-menu__list._brown .pull-down-menu__list__item > a {
    background-image: url("../../img/common/ico_oval_arrow_br_wh.svg");
    color: #9B9277;
  }
}
/*drawer設定
------------------------------- */
.header {
  z-index: 100;
}

._drawer-contact-block {
  display: none;
}

@media (max-width: 1130px) {
  .pull-down-menu__block {
    display: none;
  }
}
.pull-down-menu__btn {
  transition: 0.3s;
}
.pull-down-menu__btn._active::after {
  transform: rotate(-180deg);
}

@media (max-width: 1130px) {
  .header__nav {
    display: none;
  }
}
@media (max-width: 767px) {
  .header__nav {
    display: none;
  }
}

.bg-overlay {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: none;
}

._overflow-hidden {
  overflow: hidden;
}

/* Footer
------------------------------- */
.footer {
  background-color: #9B9277;
  padding: 72px 0 0;
}
@media only screen and (max-width: 767px) {
  .footer {
    padding: 8.44rem 0 0;
  }
}
.footer__logo {
  display: block;
  width: 202px;
}
.footer__logo img {
  display: block;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .footer__logo {
    width: 20.2rem;
  }
}
.footer__sns {
  display: flex;
  align-items: flex-start; /* 縦方向*/
  justify-content: flex-start; /*横方向*/
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 26px;
}
.footer__sns__ico {
  display: block;
  width: auto;
  height: 16px;
}
.footer__sns__ico img {
  display: block;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .footer__sns {
    gap: 2rem;
    margin-top: 3.8rem;
  }
  .footer__sns__ico {
    height: 1.9rem;
  }
  .footer__sns__ico img {
    width: auto;
    height: 100%;
  }
}
.footer__in {
  width: 96%;
  margin: 0 auto;
}
@media only screen and (min-width: 768px) {
  .footer__in {
    display: flex;
    align-items: flex-start; /* 縦方向*/
    justify-content: space-between; /*横方向*/
    flex-wrap: wrap;
    flex-wrap: nowrap;
    gap: 30px;
    max-width: 1200px;
  }
}
@media only screen and (max-width: 767px) {
  .footer__in {
    width: 29.4rem;
  }
}
.footer__cell._blockA {
  width: 202px;
  padding: 4px 0 0;
}
@media only screen and (max-width: 767px) {
  .footer__cell._blockA {
    width: 100%;
    padding: 0;
    margin-bottom: 3.7rem;
  }
}
.footer__cell._blockB {
  width: 881px;
}
@media only screen and (min-width: 768px) {
  .footer__cell._blockB {
    display: flex;
    align-items: flex-start; /* 縦方向*/
    justify-content: space-between; /*横方向*/
    flex-wrap: wrap;
    gap: 20px;
    max-width: calc(100% - 202px);
  }
}
@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .footer__cell._blockB {
    display: flex;
    align-items: flex-start; /* 縦方向*/
    justify-content: flex-start; /*横方向*/
    flex-wrap: wrap;
    gap: 30px 30px;
  }
}
@media only screen and (max-width: 767px) {
  .footer__cell._blockB {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .footer__nav {
    width: 150px;
  }
}
@media only screen and (max-width: 767px) {
  .footer__nav {
    margin-bottom: 3.6rem;
  }
}
.footer__nav__item {
  margin-bottom: 17px;
}
.footer__nav__item a {
  display: block;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.1em;
  text-align: left;
  color: #fff;
  white-space: nowrap;
}
@media only screen and (max-width: 767px) {
  .footer__nav__item {
    margin-bottom: 1.7rem;
  }
  .footer__nav__item a {
    font-size: 1.3rem;
  }
}
.footer__nav__btm {
  display: flex;
  align-items: flex-start; /* 縦方向*/
  justify-content: center; /*横方向*/
  flex-wrap: wrap;
  gap: 10px 0;
  width: 100%;
  margin-top: 54px;
  margin-bottom: 28px;
}
@media only screen and (max-width: 767px) {
  .footer__nav__btm {
    display: flex;
    align-items: flex-start; /* 縦方向*/
    justify-content: flex-start; /*横方向*/
    flex-wrap: wrap;
    gap: 1rem 0;
    width: 31.9rem;
    margin: 6.1rem auto 2.9rem;
  }
}
.footer__nav__btm__item {
  display: block;
  position: relative;
}
.footer__nav__btm__item::after {
  content: "";
  display: block;
  width: 1px;
  height: 10px;
  background-color: #fff;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -5px;
}
@media only screen and (max-width: 767px) {
  .footer__nav__btm__item::after {
    height: 1rem;
    margin-top: -0.5rem;
  }
}
.footer__nav__btm__item:last-child::after {
  display: none;
}
.footer__nav__btm__item a {
  display: block;
  font-size: 12px;
  line-height: 1.2;
  letter-spacing: 0.1em;
  color: #fff;
  padding: 0 0.95em;
  font-feature-settings: "palt";
}
@media only screen and (max-width: 767px) {
  .footer__nav__btm__item a {
    font-family: "游ゴシック", "YuGothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-size: 1.1rem;
    line-height: 1;
    letter-spacing: 0;
    padding: 0 0.95em;
  }
}

@media only screen and (max-width: 767px) {
  .copyright-sp {
    font-family: "Quattrocento", serif;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.05em;
    text-align: center;
    color: #fff;
    margin-bottom: 2.9em;
  }
}

.copyright {
  background-color: #F4F4F4;
  font-family: "Quattrocento", serif;
  font-size: 12px;
  line-height: 1.2;
  text-align: center;
  color: #9B9277;
  padding: 23px 0;
}
@media only screen and (max-width: 767px) {
  .copyright {
    font-size: 1.1rem;
    padding: 2.3rem 0 7rem;
  }
}

/* etc
------------------------------- */
.ttl-large-en {
  font-family: "Quattrocento", serif;
  font-size: 35px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: #9B9277;
  text-align: center;
  margin-bottom: 63px;
}
.ttl-large-en.sub-page {
  margin-bottom: 0;
  padding: 80px 0;
}
.ttl-large-en ._sub {
  display: block;
  font-size: 13px;
  font-family: "YuMincho", "Yu Mincho", "游明朝", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", serif;
  font-weight: 400;
  letter-spacing: 0.05em;
  margin-top: 5px;
}
@media only screen and (max-width: 767px) {
  .ttl-large-en {
    font-size: 2.8rem;
    margin-bottom: 4.7rem;
  }
  .ttl-large-en.sub-page {
    padding: 6rem 0;
  }
  .ttl-large-en ._sub {
    font-size: 1.3rem;
    margin-top: 0;
  }
}

/* btn
------------------------------- */
.btn-search {
  display: flex;
  align-items: center; /* 縦方向*/
  justify-content: center; /*横方向*/
  flex-wrap: wrap;
  width: 218px;
  height: 40px;
  font-family: "游ゴシック", "YuGothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.1em;
  text-align: center;
  color: #fff;
  padding-left: 39px;
  border: 1px solid #fff;
  position: relative;
  cursor: pointer;
  transition: 0.4s;
}
.btn-search::before {
  content: "";
  display: block;
  width: 39px;
  height: 38px;
  background-color: #fff;
  background-image: url("../../img/top/ico_search_br.svg");
  background-size: 15px auto;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}
@media only screen and (min-width: 768px) {
  .btn-search:hover {
    background-color: rgba(255, 255, 255, 0.4);
    opacity: 0.8;
  }
}
@media only screen and (max-width: 767px) {
  .btn-search {
    width: 21.8rem;
    height: 4rem;
    font-size: 1.3rem;
    padding-left: 3.9rem;
  }
  .btn-search::before {
    width: 3.9rem;
    height: calc(4rem - 2px);
    background-size: 1.5rem auto;
  }
}

.btn-anchor {
  display: block;
  width: 7px;
  height: 71px;
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
}
.btn-anchor::before {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(0) translateX(-50%);
}
.btn-anchor::after {
  content: "";
  display: block;
  width: 726px;
  aspect-ratio: 1/1;
  border-radius: 726px;
  background-color: rgba(255, 255, 255, 0.1);
  position: absolute;
  bottom: -360px;
  left: 50%;
  transition: 0.3s;
  transform: translateY(0) translateX(-50%);
  pointer-events: none;
}
.btn-anchor i {
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  animation: a 2.5s ease-in-out infinite, ahide 2.5s ease-out infinite;
}
@media only screen and (max-width: 767px) {
  .btn-anchor {
    display: block;
    width: 0.7rem;
    height: 7.1rem;
  }
  .btn-anchor::after {
    display: none;
  }
  .btn-anchor i {
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 10px;
    background-color: #fff;
    position: absolute;
  }
}

@keyframes a {
  0% {
    bottom: 92%;
  }
  100% {
    bottom: 0%;
  }
}
@keyframes ahide {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  80% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}
.btn-more {
  display: flex;
  align-items: center; /* 縦方向*/
  justify-content: center; /*横方向*/
  flex-wrap: wrap;
  width: 300px;
  max-width: 100%;
  height: 60px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.05em;
  color: #fff;
  text-align: center;
  background-color: #9B9277;
  background-image: url("../../img/top/arrow_br_wh.svg");
  background-size: 23px auto;
  background-position: right 17px center;
  background-repeat: no-repeat;
}
.btn-more._wh {
  color: #9B9277;
  background-color: #fff;
  background-image: url("../../img/top/arrow_wh_br.svg");
}
.btn-more._gr {
  background-color: #70905B;
  background-image: url("../../img/top/arrow_wh_gr.svg");
}
@media only screen and (max-width: 767px) {
  .btn-more {
    width: 29.5rem;
    height: 6rem;
    font-size: 1.3rem;
    background-size: 2.3rem auto;
    background-position: right 1.7rem center;
  }
}

/* slider
------------------------------- */
/*slide*/
.slide {
  width: auto;
  margin-left: -10px;
  margin-right: -10px;
}
.slide__in {
  width: 280px !important;
  padding: 0 10px;
}
.slide .slick-arrow {
  width: 40px;
  height: 100%;
  cursor: pointer;
  background-size: 23px auto;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
}
.slide .slick-arrow.slick-prev {
  background-image: url("../../img/common/arrow_slideL.svg");
  background-position: 0 center;
  right: 100%;
}
.slide .slick-arrow.slick-next {
  background-image: url("../../img/common/arrow_slideR.svg");
  background-position: right center;
  left: 100%;
}
@media only screen and (max-width: 767px) {
  .slide {
    width: auto;
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .slide__in {
    width: 31.5rem !important;
    padding: 0 1rem;
  }
  .slide.js-slide-sp-off .slide__in {
    margin-bottom: 3.3rem;
  }
  .slide.js-slide-sp-off .slide__in:last-child {
    margin-bottom: 0;
  }
  .slide .slick-arrow {
    width: 4rem;
    height: 100%;
    cursor: pointer;
    background-size: 2.3rem auto;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
  }
  .slide .slick-arrow.slick-prev {
    background-position: 0 center;
    right: 100%;
  }
  .slide .slick-arrow.slick-next {
    background-position: right center;
    left: 100%;
  }
}

.slideB {
  width: auto;
  margin-left: -16.6666666667px;
  margin-right: -16.6666666667px;
}
.slideB__in {
  width: 283.3333333333px !important;
  padding: 0 16.6666666667px;
}
.slideB .slick-arrow {
  width: 34px;
  height: 100%;
  cursor: pointer;
  background-size: 25px auto;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
}
.slideB .slick-arrow.slick-prev {
  background-image: url("../../img/common/arrow_slideL.svg");
  background-position: 0 113px;
  right: 100%;
}
.slideB .slick-arrow.slick-next {
  background-image: url("../../img/common/arrow_slideR.svg");
  background-position: right 0 top 113px;
  left: 100%;
}
.slideB .product {
  color: #484848;
}
.slideB .product__img {
  object-position: 50% 50%;
}
.slideB .product__meta {
  margin-top: 16px;
}
.slideB .product__meta__item {
  font-family: "YuMincho", "Yu Mincho", "游明朝", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", serif;
  padding: 0 9px;
  height: 21px;
}
.slideB .product__p {
  margin-bottom: 0;
}
@media only screen and (min-width: 768px) {
  .slideB .product__img {
    filter: grayscale(100%);
    transition: filter 0.4s;
  }
  .slideB a:hover .product__img {
    opacity: 1;
    filter: grayscale(0);
  }
}
@media only screen and (max-width: 767px) {
  .slideB {
    width: auto;
    margin-left: -1.1rem;
    margin-right: -1.1rem;
  }
  .slideB__in {
    width: 27.2rem !important;
    padding: 0 1.1rem;
  }
  .slideB .slick-arrow {
    width: 2.5rem;
    height: 100%;
    cursor: pointer;
    background-size: 2.5rem auto;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .slideB .slick-arrow.slick-prev {
    background-image: url("../../img/common/arrow_slideL.svg");
    background-position: 0 12.3rem;
    left: 3.8rem;
    right: auto;
  }
  .slideB .slick-arrow.slick-next {
    background-image: url("../../img/common/arrow_slideR.svg");
    background-position: right 0 top 12.3rem;
    right: 3.8rem;
    left: auto;
  }
  .slideB .product {
    color: #484848;
  }
  .slideB .product__img {
    height: auto;
    aspect-ratio: 1/1;
    object-position: 50% 50%;
  }
  .slideB .product__meta {
    margin-top: 1.6rem;
    margin-bottom: 0.5rem;
  }
  .slideB .product__meta__item {
    padding: 0 0.9rem;
    height: 2.1rem;
  }
  .slideB .product__p {
    font-size: 1.5rem;
    line-height: 3.2rem;
  }
}

.product {
  display: block;
  width: 100%;
}
.product__img__link {
  display: block;
}
.product__img {
  display: block;
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: 50% 100%;
}
.product__meta {
  display: flex;
  align-items: center; /* 縦方向*/
  justify-content: flex-start; /*横方向*/
  flex-wrap: wrap;
  margin: 13px 0 10px;
  gap: 3px;
}
.product__meta__item {
  display: flex;
  align-items: center; /* 縦方向*/
  justify-content: center; /*横方向*/
  flex-wrap: wrap;
  font-family: "游ゴシック", "YuGothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  height: 24px;
  padding: 0 15px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.05em;
  color: #fff;
  background-color: #70905B;
}
.product__meta__item._itemA {
  background-color: #9B9277;
}
.product__meta__item._itemB {
  background-color: #BEB292;
}
.product__wrap {
  position: relative;
}
.product__wrap .product__meta {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
}
.product__ttl {
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 0.05em;
  margin-bottom: 12px;
}
.product__ttl-s {
  font-size: 15px;
  margin-top: 18px;
  margin-bottom: 7px;
}
.product__p {
  font-size: 13px;
  line-height: 23px;
  letter-spacing: 0.05em;
  max-height: 3.7em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-bottom: 13px;
  margin-right: -0.5em;
}
.product .btn-more {
  width: 100%;
  height: 48px;
}
@media only screen and (max-width: 767px) {
  .product__img {
    height: 20rem;
    object-position: 50% 50%;
  }
  .product__meta {
    margin: 1.1rem 0 1rem;
    gap: 0.3rem;
  }
  .product__meta__item {
    display: flex;
    align-items: center; /* 縦方向*/
    justify-content: center; /*横方向*/
    flex-wrap: wrap;
    height: 2.4rem;
    padding: 0 1.5rem;
    font-size: 1.2rem;
  }
  .product__wrap .product__meta {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
  }
  .product__ttl {
    font-size: 1.8rem;
    margin-bottom: 0.6rem;
  }
  .product__ttl-s {
    font-size: 1.5rem;
    margin-top: 1.8rem;
    margin-bottom: 0.7rem;
  }
  .product__p {
    font-size: 1.3rem;
    line-height: 2.3rem;
    margin-bottom: 1.3rem;
  }
  .product .btn-more {
    height: 4.8rem;
  }
}

/*Bridal Fair
------------------------------- */
.bridal-fair {
  padding: 80px 0 120px;
  border-top: 1px solid #c9c8c0;
  background-color: #fff;
  position: relative;
}
.bridal-fair .ttl-large-en {
  width: 100%;
}
.bridal-fair > .btn-more {
  margin: 57px auto 0;
}
.bridal-fair__in {
  width: 1100px;
  max-width: 85%;
}
.bridal-fair .product__img {
  object-position: 50% 50%;
}
@media only screen and (max-width: 767px) {
  .bridal-fair {
    padding: 6rem 0 9rem;
    background-color: #fff;
    position: relative;
  }
  .bridal-fair .ttl-large-en {
    width: 100%;
    margin-bottom: 3rem;
  }
  .bridal-fair > .btn-more {
    display: none;
  }
  .bridal-fair__in {
    width: 100%;
    max-width: none;
  }
  .bridal-fair .slide {
    width: auto;
  }
  .bridal-fair .slide__in {
    width: 27.8rem !important;
    padding: 0 0.9rem;
  }
  .bridal-fair .slide .slick-arrow {
    width: 2.5rem;
    height: 100%;
    cursor: pointer;
    background-size: 2.5rem auto;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .bridal-fair .slide .slick-arrow.slick-prev {
    background-image: url("../../img/common/arrow_slideL.svg");
    background-position: 0 12.3rem;
    left: 3.8rem;
    right: auto;
  }
  .bridal-fair .slide .slick-arrow.slick-next {
    background-image: url("../../img/common/arrow_slideR.svg");
    background-position: right 0 top 12.3rem;
    right: 3.8rem;
    left: auto;
  }
  .bridal-fair .product__img {
    height: auto;
    aspect-ratio: 1/1;
    object-position: 50% 50%;
  }
}

/* breadcrumb
------------------------------- */
.breadcrumb {
  display: flex;
  align-items: center; /* 縦方向*/
  justify-content: flex-start; /*横方向*/
  flex-wrap: wrap;
  height: 45px;
  background-color: #F4F4F4;
}
.breadcrumb ol {
  display: block;
  width: 1200px;
  max-width: 96%;
  margin: 0 auto;
  font-family: "游ゴシック", "YuGothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 11px;
  line-height: 1.2;
  color: #9B9277;
}
.breadcrumb ol li {
  position: relative;
  display: inline-block;
  margin-right: 15px;
}
.breadcrumb ol li:after {
  content: ">";
  font-family: "游ゴシック", "YuGothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 11px;
  line-height: 1.2;
  color: #9B9277;
  position: absolute;
  right: -14px;
  top: -1px;
}
.breadcrumb ol li:last-child {
  margin-right: 0;
}
.breadcrumb ol li:last-child:after {
  display: none;
}
.breadcrumb ol a {
  color: #9B9277;
}
@media only screen and (max-width: 767px) {
  .breadcrumb {
    height: 5.4rem;
  }
  .breadcrumb ol {
    width: 33.5rem;
    font-size: 1.1rem;
  }
}

/* 会場を探すモーダル
------------------------------- */
.bnr_find_hall {
  display: flex;
  align-items: center; /* 縦方向*/
  justify-content: center; /*横方向*/
  flex-wrap: wrap;
  width: 270px;
  height: 60px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.05em;
  text-align: center;
  color: #fff;
  background-image: url("../../img/common/ico_search.svg");
  background-color: #9B9277;
  background-size: 15px auto;
  background-position: 20px center;
  background-repeat: no-repeat;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10;
  cursor: pointer;
}
@media only screen and (min-width: 768px) {
  .bnr_find_hall {
    opacity: 1;
  }
  .bnr_find_hall:hover {
    transition: opacity 0.4s;
    opacity: 0.8;
  }
}
@media only screen and (max-width: 767px) {
  .bnr_find_hall {
    width: 36.5rem;
    height: 4rem;
    font-size: 1.2rem;
    background-size: 1.5rem auto;
    background-position: 1.85rem center;
    bottom: 0.5rem;
    right: 0.5rem;
  }
}

.find-hall {
  display: flex;
  align-items: center; /* 縦方向*/
  justify-content: center; /*横方向*/
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;
  background-color: rgba(155, 146, 119, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: none;
}
.find-hall._flex {
  display: flex !important;
}
.find-hall__btn {
  position: absolute;
  top: 32px;
  right: 13px;
  cursor: pointer;
}
.find-hall__btn img {
  display: block;
  width: 38px;
  height: auto;
}
.find-hall__in {
  width: 1300px;
  max-width: 96%;
  max-height: 96%;
  margin: 0 auto;
  position: relative;
}
.find-hall__inner {
  display: flex;
  align-items: flex-start; /* 縦方向*/
  justify-content: center; /*横方向*/
  flex-wrap: wrap;
  width: 100%;
  max-height: 88vh;
  border-top: 62px solid #fff;
  border-bottom: 62px solid #fff;
  background-color: #fff;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
　 .find-hall__inner::-webkit-scrollbar {
  display: none;
}
.find-hall__block {
  display: flex;
  align-items: flex-start; /* 縦方向*/
  justify-content: center; /*横方向*/
  flex-wrap: wrap;
  gap: 2rem;
  width: 1100px;
  max-width: 86%;
  margin: 0 auto;
}
.find-hall__block .product {
  width: 250px;
}
@media only screen and (max-width: 767px) {
  .find-hall {
    width: 100%;
    height: 100vh;
    padding-top: 2rem;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .find-hall__btn {
    top: 2.1rem;
    right: 1.1rem;
    cursor: pointer;
  }
  .find-hall__btn img {
    width: 3.8rem;
    height: auto;
  }
  .find-hall__btn__btm {
    position: absolute;
    bottom: 2.5rem;
    left: 50%;
    margin-left: -5rem;
  }
  .find-hall__btn__btm img {
    display: block;
    width: 10rem;
    height: auto;
  }
  .find-hall__in {
    width: 33.5rem;
    max-width: none;
    max-height: none;
    margin-bottom: 12rem;
  }
  .find-hall__inner {
    width: 100%;
    height: auto;
    max-height: none;
    border-top: 9rem solid #fff;
    border-bottom: 9rem solid #fff;
    overflow-y: hidden;
  }
  .find-hall__block {
    display: flex;
    align-items: flex-start; /* 縦方向*/
    justify-content: center; /*横方向*/
    flex-wrap: wrap;
    gap: 2rem;
    width: 26rem;
    max-width: 86%;
  }
  .find-hall__block .product {
    width: 100%;
  }
}

.nav__box {
  display: flex;
  flex-wrap: wrap;
  gap: 0.7rem 0.5%;
}
@media only screen and (max-width: 767px) {
  .nav__box {
    gap: 0.7rem 0;
    justify-content: space-between;
  }
}
.nav__box li {
  width: 16.25%;
}
@media only screen and (max-width: 767px) {
  .nav__box li {
    width: 49%;
  }
  .nav__box li.all {
    width: 100%;
  }
}
.nav__box li a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: #9B9277;
  background: #F4F4F4;
  letter-spacing: 0.1em;
  text-align: center;
  transition: all 0.4s;
  height: 42px;
}
@media only screen and (max-width: 767px) {
  .nav__box li a {
    font-size: 1.2rem;
    height: 4rem;
  }
}
.nav__box li a:hover {
  color: #fff;
  background: #9B9277 !important;
}
.nav__box li a.active {
  color: #fff;
  background: #9B9277 !important;
}
.nav__box.white li a {
  background: #fff;
}
.nav__box.gold li a {
  background: #DCD9D2;
}
@media only screen and (min-width: 767px) {
  .nav__box.row05 li {
    width: 19.5%;
  }
}

/* 相談カウンター
------------------------------- */
.counter {
  width: 100%;
  background-color: #F4F4F4;
  padding: 93px 0 0;
}
@media only screen and (max-width: 767px) {
  .counter {
    padding-top: 7.8rem;
  }
}
.counter .ttl-large-en {
  font-size: 20px;
  color: #9B9277;
  margin-bottom: 65px;
}
.counter .ttl-large-en ._sub {
  margin-top: 10px;
}
@media only screen and (max-width: 767px) {
  .counter .ttl-large-en {
    font-size: 2rem;
    margin-bottom: 5.8rem;
  }
  .counter .ttl-large-en ._sub {
    margin-top: 0.6rem;
  }
}
.counter .btn-more {
  margin: 22px auto 37px;
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .counter .btn-more {
    width: 100%;
    margin-top: 3.7rem;
    margin-bottom: 4rem;
  }
}
.counter__in {
  background-image: url(../../img/common/bg_counseling.jpg.webp);
  background-size: cover;
  background-position: center 0;
  background-repeat: no-repeat;
  padding: 89px 20px 75px;
  position: relative;
  overflow: hidden;
}
.no-webp .counter__in {
  background-image: url(../../img/common/bg_counseling.jpg);
}
.counter__in::before {
  content: "";
  display: block;
  width: 726px;
  aspect-ratio: 1/1;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(-44%) translateX(-50%);
  z-index: 0;
}
@media only screen and (max-width: 767px) {
  .counter__in {
    width: 100%;
    max-width: 100%;
    background-image: url(../../img/common/bg_counseling_sp.jpg.webp);
    padding: 7.7rem 2rem 9.8rem;
  }
  .no-webp .counter__in {
    background-image: url(../../img/common/bg_counseling_sp.jpg);
  }
  .counter__in::before {
    width: 34.9rem;
  }
}
.counter__ttl {
  font-family: "Quattrocento", serif;
  font-size: 18px;
  letter-spacing: 1.6;
  letter-spacing: 0.1em;
  color: #fff;
  text-align: center;
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .counter__ttl {
    font-size: 1.8rem;
  }
}
.counter__ttl-sub {
  font-size: 13px;
  letter-spacing: 1.6;
  letter-spacing: 1.6;
  letter-spacing: 0.05em;
  color: #fff;
  text-align: center;
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .counter__ttl-sub {
    flex: 1.3rem;
  }
}
.counter__list {
  display: flex;
  align-items: flex-start; /* 縦方向*/
  justify-content: center; /*横方向*/
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
  max-width: 1240px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto 38px;
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .counter__list {
    gap: 2rem;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 3.5rem;
  }
}
.counter__item {
  width: calc((100% - 1px - 45px) / 4);
}
@media only screen and (max-width: 767px) {
  .counter__item {
    width: 100%;
  }
}
.counter__item .btn-oval-arrow {
  width: 100%;
}
.counter .tel-link {
  position: relative;
  z-index: 1;
}
.counter .tel-link__time {
  position: relative;
  z-index: 1;
}
.counter .btm-nav {
  background-image: url(../../img/common/bg_counseling.jpg.webp);
  background-size: cover;
  background-position: center 0;
  background-repeat: no-repeat;
  padding: 74px 20px 54px;
  position: relative;
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  .counter .btm-nav {
    width: 100%;
    max-width: 100%;
    background-image: url(../../img/common/bg_counseling_sp.jpg.webp);
    background-color: #05090a;
    background-size: 100% auto;
    padding: 3.6rem 2rem 4.9rem;
  }
}
.no-webp .counter .btm-nav {
  background-image: url(../../img/common/bg_counseling.jpg);
}
@media only screen and (max-width: 767px) {
  .no-webp .counter .btm-nav {
    background-image: url(../../img/common/bg_counseling_sp.jpg);
  }
}
.counter .btm-nav::before {
  content: "";
  display: block;
  width: 726px;
  aspect-ratio: 1/1;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(-44%) translateX(-50%);
  z-index: 0;
}
@media only screen and (max-width: 767px) {
  .counter .btm-nav::before {
    width: 34.9rem;
  }
}
.counter .btm-nav_in {
  border-bottom: 1px solid #fff;
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .counter .btm-nav_in {
    border-bottom: 0;
  }
}
.counter .btm-nav .drawer-contact__list {
  margin-bottom: 50px;
}
@media only screen and (max-width: 767px) {
  .counter .btm-nav .drawer-contact__list {
    margin-bottom: 4.1rem;
  }
}
.counter .btm-nav .drawer-contact__ttl-mid {
  color: #fff;
}
.counter .btm-nav .btn-more._wh {
  width: 275px;
  color: #70905B;
  background-image: url(../../img/common/ico_oval_arrow_gr.svg);
  margin-top: 44px;
  margin-bottom: 28px;
}
@media only screen and (max-width: 767px) {
  .counter .btm-nav .btn-more._wh {
    width: 29.5rem;
    margin-top: 4.1rem;
    margin-bottom: 2.1rem;
  }
}
.counter .btm-nav .tel-link {
  font-size: 28px;
}
@media only screen and (max-width: 767px) {
  .counter .btm-nav .tel-link {
    font-size: 2.2rem;
  }
}
.counter .btm-nav .tel-link__time {
  margin-top: 6px;
}
@media only screen and (max-width: 767px) {
  .counter .btm-nav .tel-link__time {
    margin-top: 0;
  }
}

.tel-link {
  font-family: "Quattrocento", serif;
  font-size: 30px;
  line-height: 1.4;
  letter-spacing: 0.1em;
  color: #fff;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .tel-link {
    font-size: 2.8rem;
  }
}
.tel-link a {
  color: #fff;
}
.tel-link__time {
  font-size: 13px;
  line-height: 1.4;
  letter-spacing: 0.05em;
  color: #fff;
  text-align: center;
  margin-top: 1px;
}
@media only screen and (max-width: 767px) {
  .tel-link__time {
    font-size: 1.3rem;
    margin-top: 0.1rem;
  }
}

/* ブライダルフェア おすすめのフェア
------------------------------- */
.bridal-fair-recommend {
  padding: 85px 0 74px;
  background-color: #fff;
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  .bridal-fair-recommend {
    padding: 6.7rem 0 7.2rem;
  }
}
.bridal-fair-recommend .ttl-large-en {
  margin-bottom: 33px;
}
@media only screen and (max-width: 767px) {
  .bridal-fair-recommend .ttl-large-en {
    margin-bottom: 3.3rem;
  }
}
.bridal-fair-recommend .btn-more {
  margin: 53px auto 0;
}
@media only screen and (max-width: 767px) {
  .bridal-fair-recommend .btn-more {
    margin: 4.1rem auto 0;
    background-image: none;
  }
}
.bridal-fair-recommend__ttl {
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.05em;
  text-align: center;
  color: #7E7573;
  margin-bottom: 28px;
}
@media only screen and (max-width: 767px) {
  .bridal-fair-recommend__ttl {
    font-size: 1.5rem;
    margin-bottom: 2.4rem;
  }
}
.bridal-fair-recommend__slide {
  width: 100%;
}
.bridal-fair-recommend__slide .slick-arrow {
  width: 36px;
  height: 100%;
  background-size: 23px auto;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  z-index: 1;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .bridal-fair-recommend__slide .slick-arrow {
    width: 3.6rem;
    background-size: 2.5rem auto;
  }
}
.bridal-fair-recommend__slide .slick-arrow.slick-prev {
  background-image: url("../../img/common/ico_oval_arrow_left_br.svg");
  left: 50%;
  margin-left: -398px;
}
@media only screen and (max-width: 767px) {
  .bridal-fair-recommend__slide .slick-arrow.slick-prev {
    left: 0;
    margin-left: 0;
  }
}
.bridal-fair-recommend__slide .slick-arrow.slick-next {
  background-image: url("../../img/common/ico_oval_arrow_right_br.svg");
  right: 50%;
  margin-right: -398px;
}
@media only screen and (max-width: 767px) {
  .bridal-fair-recommend__slide .slick-arrow.slick-next {
    right: 0;
    margin-right: 0;
  }
}
.bridal-fair-recommend__slide__in {
  display: -webkit-flex !important;
  display: flex !important;
  align-items: flex-start; /* 縦方向*/
  justify-content: center; /*横方向*/
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 724px !important;
  background-color: #fff;
  margin: 0 18px;
}
@media only screen and (max-width: 767px) {
  .bridal-fair-recommend__slide__in {
    display: block !important;
    width: 29.5rem !important;
    margin: 0 0.9rem;
  }
}
.bridal-fair-recommend__slide__img {
  width: 260px;
  aspect-ratio: 1/1;
}
@media only screen and (max-width: 767px) {
  .bridal-fair-recommend__slide__img {
    width: 100%;
  }
}
.bridal-fair-recommend__slide__img picture {
  display: block;
  width: 100%;
  height: 100%;
}
.bridal-fair-recommend__slide__img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bridal-fair-recommend__slide__text {
  width: calc(100% - 260px);
  min-height: 260px;
  border: 1px solid #bfbfbf;
  border-left: 0;
  padding: 33px 36px 15px 40px;
}
@media only screen and (max-width: 767px) {
  .bridal-fair-recommend__slide__text {
    width: 100%;
    min-height: 0;
    border-left: 1px solid #bfbfbf;
    border-top: 0;
    padding: 2.5rem 2rem 2.5rem;
  }
}
.bridal-fair-recommend__slide__ttl {
  font-size: 16px;
  line-height: 1.7;
  letter-spacing: 0.05em;
  color: #70905B;
  text-align: left;
  margin-bottom: 6px;
}
@media only screen and (max-width: 767px) {
  .bridal-fair-recommend__slide__ttl {
    font-size: 1.6rem;
    line-height: 1.5;
    margin-bottom: 0.9rem;
  }
}
.bridal-fair-recommend__slide__desc {
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.05em;
  color: #7E7573;
  text-align: left;
  margin-bottom: 11px;
}
@media only screen and (max-width: 767px) {
  .bridal-fair-recommend__slide__desc {
    min-height: 3.9rem;
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
  }
}
.bridal-fair-recommend__slide__caption {
  font-size: 13px;
  line-height: 1.8461538462;
  letter-spacing: 0.05em;
  text-align: left;
  margin-bottom: 14px;
}
@media only screen and (max-width: 767px) {
  .bridal-fair-recommend__slide__caption {
    font-size: 1.3rem;
    margin-bottom: 1.4rem;
  }
}
.bridal-fair-recommend__slide__btn {
  display: flex;
  align-items: center; /* 縦方向*/
  justify-content: center; /*横方向*/
  flex-wrap: wrap;
  width: 170px;
  height: 40px;
  font-size: 13px;
  line-height: 1.2;
  letter-spacing: 0.05em;
  color: #fff;
  background-color: #70905B;
}
@media only screen and (max-width: 767px) {
  .bridal-fair-recommend__slide__btn {
    width: 100%;
    height: 4rem;
    font-size: 1.3rem;
  }
}

/* ウェディングプラン
------------------------------- */
.wedding-plan {
  padding: 67px 0 108px;
  border-top: 1px solid #c9c8c0;
  border-bottom: 1px solid #c9c8c0;
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  .wedding-plan {
    padding: 5.9rem 0 9.7rem;
  }
}
.wedding-plan .ttl-large-en {
  margin-bottom: 43px;
}
@media only screen and (max-width: 767px) {
  .wedding-plan .ttl-large-en {
    margin-bottom: 3.8rem;
  }
}
.wedding-plan .btn-more {
  margin: 44px auto 0;
}
@media only screen and (max-width: 767px) {
  .wedding-plan .btn-more {
    margin: 4.4rem auto 0;
  }
}
.wedding-plan__slide {
  max-width: 1100px;
  margin: 0 auto;
}
@media only screen and (max-width: 767px) {
  .wedding-plan__slide {
    margin: 0 -2rem;
  }
}
.wedding-plan__slide .slick-arrow {
  width: 50px;
  height: 100%;
  background-size: 25px auto;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  z-index: 1;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .wedding-plan__slide .slick-arrow {
    width: 5rem;
    background-size: 2.5rem auto;
  }
}
.wedding-plan__slide .slick-arrow.slick-prev {
  background-image: url("../../img/common/ico_oval_arrow_left_br.svg");
  background-position: 0 168px;
  left: 0;
  transform: translateX(-100%);
}
@media only screen and (max-width: 1200px) {
  .wedding-plan__slide .slick-arrow.slick-prev {
    transform: translateX(-15px);
  }
}
@media only screen and (max-width: 1200px) and (max-width: 767px) {
  .wedding-plan__slide .slick-arrow.slick-prev {
    background-position: right 0.2rem top 16.8rem;
    transform: translateX(0);
  }
}
.wedding-plan__slide .slick-arrow.slick-next {
  background-image: url("../../img/common/ico_oval_arrow_right_br.svg");
  background-position: right 0 top 168px;
  right: 0;
  transform: translateX(100%);
}
@media only screen and (max-width: 1200px) {
  .wedding-plan__slide .slick-arrow.slick-next {
    transform: translateX(15px);
  }
}
@media only screen and (max-width: 1200px) and (max-width: 767px) {
  .wedding-plan__slide .slick-arrow.slick-next {
    background-position: left 0.2rem top 16.8rem;
    transform: translateX(0);
  }
}
.wedding-plan__slide__in {
  width: 260px !important;
  margin: 0 20px 0 0;
}
@media only screen and (max-width: 767px) {
  .wedding-plan__slide__in {
    width: 25rem !important;
    margin: 0 1rem 0;
  }
}
.wedding-plan__slide__img {
  width: 100%;
  aspect-ratio: 1/1;
}
.wedding-plan__slide__img a,
.wedding-plan__slide__img picture {
  display: block;
  width: 100%;
  height: 100%;
}
.wedding-plan__slide__img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.wedding-plan__slide__text {
  font-size: 14px;
  line-height: 1.7857142857;
  position: relative;
  color: #70905B;
}
@media only screen and (max-width: 767px) {
  .wedding-plan__slide__text {
    font-size: 1.4rem;
  }
}
.wedding-plan__slide__meta {
  display: flex;
  align-items: stretch; /* 縦方向*/
  justify-content: flex-start; /*横方向*/
  flex-wrap: wrap;
  gap: 5px;
  padding: 13px 0 23px;
}
@media only screen and (max-width: 767px) {
  .wedding-plan__slide__meta {
    padding: 1.1rem 0 1.3rem;
  }
}
.wedding-plan__slide__chip {
  display: flex;
  align-items: center; /* 縦方向*/
  justify-content: center; /*横方向*/
  flex-wrap: wrap;
  min-width: 78px;
  min-height: 24px;
  font-family: "游ゴシック", "YuGothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 12px;
  line-height: 1.1;
  letter-spacing: 0.05em;
  color: #fff;
  background-color: #70905B;
  padding: 3px 10px;
}
@media only screen and (max-width: 767px) {
  .wedding-plan__slide__chip {
    min-width: 7.8rem;
    min-height: 2.4rem;
    font-size: 1.2rem;
    padding: 0.3rem 1rem;
  }
}
.wedding-plan__slide__chip._itemA {
  background-color: #70905B;
}
.wedding-plan__slide__chip._itemB {
  background-color: #BEB292;
}
.wedding-plan__slide__ttl {
  letter-spacing: 0.03em;
}
@media only screen and (max-width: 767px) {
  .wedding-plan__slide__ttl {
    letter-spacing: 0.05em;
    line-height: 1.7;
  }
}
.wedding-plan__slide__ttl a {
  display: block;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
}
.wedding-plan__slide__desc {
  letter-spacing: 0.05em;
  line-height: inherit;
}
.wedding-plan__slide__add-info {
  letter-spacing: 0.05em;
  line-height: inherit;
}
.wedding-plan__slide__btn {
  display: flex;
  align-items: center; /* 縦方向*/
  justify-content: flex-start; /*横方向*/
  flex-wrap: wrap;
  width: 100%;
  min-height: 36px;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0.05em;
  color: #70905B;
  background-color: #f0efe9;
  padding: 4px 18px;
  margin-top: 9px;
}
@media only screen and (max-width: 767px) {
  .wedding-plan__slide__btn {
    min-height: 3.6rem;
    font-size: 1.6rem;
    padding: 0.4rem 1.8rem;
    margin-top: 0.5rem;
  }
}

/* 2カラム
------------------------------- */
.column-2 {
  display: flex;
  align-items: stretch; /* 縦方向*/
  justify-content: space-between; /*横方向*/
  flex-wrap: wrap;
  max-width: 1130px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}
@media only screen and (max-width: 767px) {
  .column-2 {
    flex-direction: column-reverse;
    padding-left: 0;
    padding-right: 0;
  }
}
.column-2__main {
  width: 820px;
  max-width: calc(100% - 190px - 15px);
}
@media only screen and (max-width: 767px) {
  .column-2__main {
    max-width: 100%;
  }
}
.column-2__side {
  width: 190px;
  max-width: 100%;
}
@media only screen and (max-width: 767px) {
  .column-2__side {
    width: 100%;
  }
}

/* modal
------------------------------- */
.js-img-modal {
  cursor: pointer;
}
.js-img-modal_btn {
  transition: opacity 0.4s;
}
.js-img-modal_btn:hover {
  opacity: 0.8;
}

.img__modal__bg {
  display: none;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
}
.img__modal__block {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
}
@media only screen and (max-width: 767px) {
  .img__modal__block {
    width: 100vw;
  }
}
.img__modal__wrap {
  display: flex;
  align-items: center; /* 縦方向*/
  justify-content: center; /*横方向*/
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  padding: 0 50px;
}
@media only screen and (max-width: 767px) {
  .img__modal__wrap {
    padding: 0 2rem;
  }
}
.img__modal__inner {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .img__modal__inner {
    padding: 1rem;
    border-radius: 1rem;
  }
}
.img__modal__inner picture {
  display: block;
  width: 100%;
  height: 100%;
}
.img__modal__inner img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 80vh;
}
.img__modal__close {
  display: block;
  width: 30px;
  aspect-ratio: 1/1;
  border-radius: 100%;
  border: 3px solid #fff;
  background-color: #9B9277;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-50%) translateX(50%);
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .img__modal__close {
    width: 3rem;
    border: 0.3rem solid #fff;
  }
}
.img__modal__close::before {
  content: "";
  display: block;
  width: 16px;
  height: 1px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) rotate(45deg);
}
@media only screen and (max-width: 767px) {
  .img__modal__close::before {
    width: 1.6rem;
  }
}
.img__modal__close::after {
  content: "";
  display: block;
  width: 16px;
  height: 1px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) rotate(-45deg);
}
@media only screen and (max-width: 767px) {
  .img__modal__close::after {
    width: 1.6rem;
  }
}

/* カード型アイテム共通デザイン ▼▼ */
.block__cover {
  display: flex;
  gap: 0 8%;
  align-items: flex-start;
}
@media only screen and (max-width: 767px) {
  .block__cover {
    flex-wrap: wrap;
    gap: 10rem 0;
  }
}
.block__contents {
  width: 68.7%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 58px 0;
}
@media only screen and (max-width: 767px) {
  .block__contents {
    width: 100%;
    flex-wrap: wrap;
    gap: 3.4rem 0;
  }
}
.block__main {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 4%;
}
@media only screen and (max-width: 767px) {
  .block__main {
    gap: 2.6rem 0;
  }
}
.block__item {
  width: 48%;
}
@media only screen and (max-width: 767px) {
  .block__item {
    width: 100%;
  }
}
.block__item .img__cover {
  width: 100%;
}
.block__item .img__cover img {
  width: 100%;
}
.block__desc {
  background: #F4F4F4;
  padding: 25.3px 25px 29.7px;
}
@media only screen and (max-width: 767px) {
  .block__desc {
    padding-top: 1.9rem;
    padding-right: 2.1rem;
    padding-bottom: 3rem;
    padding-left: 2.1rem;
  }
}
.block__flex {
  display: flex;
  align-items: center;
  gap: 0 10px;
  margin: 0 0 14px;
}
.block__arrow {
  position: relative;
  font-family: "Quattrocento", serif;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.1em;
  color: #9B9277;
}
@media only screen and (max-width: 767px) {
  .block__arrow {
    font-size: 1.3rem;
    letter-spacing: 0.18em;
  }
}
.block__arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(45deg) skew(-1deg, -1deg);
  z-index: 0;
  width: 15px;
  height: 15px;
  border: solid 1.4px #9B9277;
}
@media only screen and (max-width: 767px) {
  .block__arrow::before {
    border-width: 0.1rem;
  }
}
.block__arrow.no__handle {
  color: #ccc;
}
.block__arrow.no__handle:before {
  border-color: #ccc;
}
.block__arrows {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: solid 1px #d9d9d9;
  padding: 20px 0 0;
}
@media only screen and (max-width: 767px) {
  .block__arrows {
    padding-top: 1.3rem;
  }
}
.block__arrows__box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .block__arrows__box {
    margin-bottom: 0.6rem;
  }
}
.block__arrows__prev {
  margin: 0 0 0 12px;
  padding: 0 0 0 17px;
}
@media only screen and (max-width: 767px) {
  .block__arrows__prev {
    margin: 0.5rem;
  }
}
.block__arrows__prev::before {
  left: 0;
  border-top: none;
  border-right: none;
}
.block__arrows__next {
  margin: 0 12px 0 0;
  padding: 0 17px 0 0;
}
@media only screen and (max-width: 767px) {
  .block__arrows__next {
    margin: 0 0.5rem 0 0;
    padding: 0 1.7rem 0 0;
  }
}
.block__arrows__next::before {
  right: 0;
  border-left: none;
  border-bottom: none;
}
.block__arrows .item__underbar__link {
  margin: 0 auto;
}

.item__tag {
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2em;
  letter-spacing: 0.05em;
  background: #9B9277;
  color: #fff;
  margin-right: 5px;
  padding: 6px 20.8px;
}
@media only screen and (max-width: 767px) {
  .item__tag {
    font-size: 1.2rem;
    margin-right: 0.5rem;
  }
}
.item__date {
  font-family: "Quattrocento", serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 2.1rem;
  letter-spacing: 0.1em;
  color: #9B9277;
}
@media only screen and (max-width: 767px) {
  .item__date {
    font-size: 1.4rem;
  }
}
.item__txt {
  font-size: 15px;
  font-weight: 500;
  line-height: 2em;
  letter-spacing: 0.05em;
  color: #484848;
  margin: 0 0 5px;
}
@media only screen and (max-width: 767px) {
  .item__txt {
    font-size: 1.5rem;
  }
}
.item__underbar__link {
  position: relative;
  display: block;
  width: 100%;
  max-width: 150px;
  font-size: 12px;
  font-weight: 500;
  line-height: 2.2em;
  letter-spacing: 0.05em;
  color: #9B9277;
  margin: 0 0 0 auto;
  padding: 2.5px 0;
  border-bottom: solid 1px #9B9277;
}
@media only screen and (max-width: 767px) {
  .item__underbar__link {
    max-width: 15rem;
    font-size: 1.2rem;
  }
}
.item__underbar__link::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 0;
  width: 21.6px;
  height: 21.6px;
  border-radius: 50%;
  border: solid 1.4px #9B9277;
  box-sizing: border-box;
}
@media only screen and (max-width: 767px) {
  .item__underbar__link::before {
    width: 2.16rem;
    height: 2.16rem;
    border: solid 1px #9B9277;
  }
}
.item__underbar__link::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 6%;
  transform: translateY(-50%) rotate(45deg) skew(5deg, 5deg);
  z-index: 0;
  display: flex;
  align-items: center;
  width: 4px;
  height: 4px;
  border-top: solid 1.4px #9B9277;
  border-right: solid 1.4px #9B9277;
  border-left: none;
  border-bottom: none;
}
@media only screen and (max-width: 767px) {
  .item__underbar__link::after {
    width: 0.4rem;
    height: 0.4rem;
    border-top: solid 1px #9B9277;
    border-right: solid 1px #9B9277;
    border-left: none;
    border-bottom: none;
  }
}

.sidebar {
  width: 24%;
}
@media only screen and (max-width: 767px) {
  .sidebar {
    width: 100%;
    padding: 0 20px;
  }
}
.sidebar__item:not(:last-child) {
  margin-bottom: 60px;
}
.sidebar__ttl {
  font-family: "Quattrocento", serif;
  font-size: 18px;
  line-height: 1.5em;
  letter-spacing: 0.15em;
  color: #9B9277;
  margin: -5px 0 25.5px;
  padding: 0 0 12px;
  border-bottom: solid 1px #e6e6e6;
}
@media only screen and (max-width: 767px) {
  .sidebar__ttl {
    font-size: 1.8rem;
  }
}
.sidebar ul li {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.05em;
}
@media only screen and (max-width: 767px) {
  .sidebar ul li {
    font-size: 1.3rem;
  }
}
.sidebar ul li:not(:last-child) {
  margin-bottom: 14px;
}
.sidebar ul a {
  color: #484848;
  opacity: 1;
  transition: all 0.4s;
}
.sidebar ul a:hover {
  opacity: 0.8;
}

.information .base {
  max-width: 1130px;
}
@media only screen and (max-width: 767px) {
  .information .base {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}
.information__block__cover {
  display: flex;
  gap: 0 8%;
}
@media only screen and (max-width: 767px) {
  .information__block__cover {
    flex-wrap: wrap;
    gap: 7.5rem;
  }
}
.information__block__contents {
  width: 68.7%;
}
@media only screen and (max-width: 767px) {
  .information__block__contents {
    width: 100%;
  }
}
.information__block__main {
  display: flex;
  flex-wrap: wrap;
  gap: 0 4%;
}
@media only screen and (max-width: 767px) {
  .information__block__main {
    gap: 0;
  }
}

.pagenation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}
@media only screen and (max-width: 767px) {
  .pagenation {
    margin-top: 4rem;
  }
}
.pagenation .page-numbers {
  font-size: 13px;
  color: #9B9277;
  width: 21.6px;
  height: 21.6px;
  border-radius: 50%;
  border: solid 1px #fff;
  display: grid;
  place-items: center;
  text-align: center;
  transition: all 0.4s ease;
  margin: 0 2px;
}
@media only screen and (max-width: 767px) {
  .pagenation .page-numbers {
    font-size: 1.3rem;
    width: 2.16rem;
    height: 2.16rem;
    margin: 0 0.2rem;
  }
}
.pagenation .page-numbers.current {
  color: #fff;
  background: #9B9277;
  border: solid 1px #9B9277;
}
.pagenation .prev,
.pagenation .next {
  position: relative;
  width: 21.6px;
  height: 21.6px;
  border-radius: 50%;
  border: solid 1px #F4F4F4;
  background: #F4F4F4;
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .pagenation .prev,
  .pagenation .next {
    width: 2.16rem;
    height: 2.16rem;
  }
}
.pagenation .prev::after,
.pagenation .next::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(45deg) skew(5deg, 5deg);
  z-index: 0;
  display: flex;
  align-items: center;
  width: 4px;
  height: 4px;
}
@media only screen and (max-width: 767px) {
  .pagenation .prev::after,
  .pagenation .next::after {
    width: 0.4rem;
    height: 0.4rem;
  }
}
.pagenation .prev {
  margin: 0 17px 0 0;
}
@media only screen and (max-width: 767px) {
  .pagenation .prev {
    margin-right: 1.7rem;
  }
}
.pagenation .prev::after {
  left: 40%;
  border-top: none;
  border-right: none;
  border-left: solid 1.4px #9B9277;
  border-bottom: solid 1.4px #9B9277;
}
@media only screen and (max-width: 767px) {
  .pagenation .prev::after {
    border-top: none;
    border-right: none;
    border-left: solid 1px #9B9277;
    border-bottom: solid 1px #9B9277;
  }
}
.pagenation .next {
  margin: 0 0 0 17px;
}
.pagenation .next::after {
  right: 40%;
  border-top: solid 1.4px #9B9277;
  border-right: solid 1.4px #9B9277;
  border-left: none;
  border-bottom: none;
}
@media only screen and (max-width: 767px) {
  .pagenation .next::after {
    border-top: solid 1px #9B9277;
    border-right: solid 1px #9B9277;
    border-left: none;
    border-bottom: none;
  }
}

.detail__ttl {
  font-size: 20px;
  letter-spacing: 0.1em;
  color: #9B9277;
  margin: 0 0 13px;
}
@media only screen and (max-width: 767px) {
  .detail__ttl {
    font-size: 1.6rem;
    line-height: 2em;
  }
}
.detail__ttl.sub {
  margin: 0 0 27px;
}
.detail__txt {
  font-size: 13px;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.05em;
  margin: 0 0 74px;
}
@media only screen and (max-width: 767px) {
  .detail__txt {
    font-size: 1.3rem;
    margin-bottom: 5.5rem;
  }
}
.detail__contents__cover {
  margin: 0 0 60px;
}
.detail__contents__cover .img__cover:not(:last-of-type) {
  margin: 0 0 30px;
}
@media only screen and (max-width: 767px) {
  .detail__contents__cover .img__cover:not(:last-of-type) {
    margin-bottom: 2rem;
  }
}
.detail__contents__cover:last-child {
  margin: 0 0 100px;
}
.detail__contents__cover.contents03 {
  margin: 0 0 100px;
}
@media only screen and (max-width: 767px) {
  .detail__contents__cover.contents03 {
    margin-bottom: 9.7rem;
  }
}
.detail__contents__cover.contents03 .detail__txt {
  margin: 0 0 55px;
}
@media only screen and (max-width: 767px) {
  .detail__contents__cover.contents02 .detail__ttl, .detail__contents__cover.contents03 .detail__ttl {
    font-size: 1.8rem;
    margin-bottom: 2.3rem;
  }
}

.post__detail {
  padding: 0 0 100px;
  font-size: 14px;
}
@media only screen and (max-width: 767px) {
  .post__detail {
    padding: 0 0 6rem;
    font-size: 1.4rem;
  }
}
.post__detail p {
  margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .post__detail p {
    margin-bottom: 3rem;
  }
}
.post__detail a {
  color: #BEB292;
  text-decoration: underline;
}
.post__detail h2 {
  font-size: 20px;
  color: #9B9277;
  position: relative;
  margin-bottom: 15px;
}
@media only screen and (max-width: 767px) {
  .post__detail h2 {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
}
.post__detail h3 {
  font-size: 18px;
  padding-bottom: 4px;
  border-bottom: 2px solid #e5e6eb;
  margin-bottom: 15px;
}
@media only screen and (max-width: 767px) {
  .post__detail h3 {
    font-size: 1.6rem;
    padding-bottom: 0.4rem;
    margin-bottom: 1.5rem;
  }
}
.post__detail ul {
  list-style: disc;
  padding-left: 15px;
}
@media only screen and (max-width: 767px) {
  .post__detail ul {
    padding-left: 1.5rem;
  }
}
.post__detail ul li {
  line-height: 1.6em;
}
.post__detail ol {
  list-style: decimal;
  padding-left: 18px;
}
@media only screen and (max-width: 767px) {
  .post__detail ol {
    padding-left: 1.8rem;
  }
}
.post__detail ol li {
  line-height: 1.6em;
}

.page-mv {
  display: flex;
  align-items: center; /* 縦方向*/
  justify-content: center; /*横方向*/
  flex-wrap: wrap;
  align-content: center;
  width: 100%;
  height: 640px;
  padding-bottom: 10px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .page-mv {
    height: 53rem;
    padding-bottom: 0;
  }
}
.page-mv::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.26);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.page-mv__ttl {
  width: 100%;
  font-family: "Quattrocento", serif;
  font-size: 13px;
  height: 1.1;
  letter-spacing: 0.1em;
  text-align: center;
  color: #fff;
  margin-bottom: 15px;
  position: relative;
  z-index: 2;
}
@media only screen and (max-width: 767px) {
  .page-mv__ttl {
    font-size: 1.3rem;
    margin-bottom: 1.5rem;
  }
}
.page-mv__ttl.second__ttl {
  margin: 0;
}
.page-mv__ttl.three__ttl {
  font-size: 30px;
}
@media only screen and (max-width: 767px) {
  .page-mv__ttl.three__ttl {
    font-size: 2.2rem;
  }
}
.page-mv__ttl.ttl02 {
  margin-bottom: 5px;
}
@media only screen and (max-width: 767px) {
  .page-mv__ttl.ttl02 {
    margin-bottom: 0.5rem;
  }
}
.page-mv__sub-ttl {
  width: 100%;
  font-family: "YuMincho", "Yu Mincho", "游明朝", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", serif;
  font-size: 30px;
  line-height: 1.2;
  letter-spacing: 0.05em;
  text-align: center;
  color: #fff;
  position: relative;
  z-index: 2;
}
@media only screen and (max-width: 767px) {
  .page-mv__sub-ttl {
    font-size: 2.2rem;
  }
}
.page-mv__sub-ttl.ttl02 {
  margin: 0 0 10px;
}
@media only screen and (max-width: 767px) {
  .page-mv__sub-ttl.ttl02 {
    margin: 0 0 1rem;
  }
}
.page-mv__bg {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}