@charset "UTF-8";

// メディアクエリ
//-------------------------------

// ブレイクポイント
$break-pointPC: 768px;//PC min-width
$break-pointSP: 767px;//スマホ max-width

@mixin mdPC {
  @media only screen and (min-width:$break-pointPC) {
    @content
  }
}

@mixin mdSP {
  @media only screen and (max-width:$break-pointSP) {
    @content
  }
}


// flex box設定
//-------------------------------

@mixin fxb{
  display: flex;
  align-items: center; /* 縦方向*/
  justify-content: center; /*横方向*/
  flex-wrap: wrap;
}

@mixin fxb_c_s{
  display: flex;
  align-items: center; /* 縦方向*/
  justify-content: flex-start; /*横方向*/
  flex-wrap: wrap;
}

@mixin fxb_c_e{
  display: flex;
  align-items: center; /* 縦方向*/
  justify-content: flex-end; /*横方向*/
  flex-wrap: wrap;
}

@mixin fxb_s_s{
  display: flex;
  align-items: flex-start; /* 縦方向*/
  justify-content: flex-start; /*横方向*/
  flex-wrap: wrap;
}

@mixin fxb_s_e{
  display: flex;
  align-items: flex-start; /* 縦方向*/
  justify-content: flex-end; /*横方向*/
  flex-wrap: wrap;
}

@mixin fxb_st_s{
  display: flex;
  align-items: stretch; /* 縦方向*/
  justify-content: flex-start; /*横方向*/
  flex-wrap: wrap;
}

@mixin fxb_st_b{
  display: flex;
  align-items: stretch; /* 縦方向*/
  justify-content:space-between; /*横方向*/
  flex-wrap: wrap;
}

@mixin fxb_s_c{
  display: flex;
  align-items: flex-start; /* 縦方向*/
  justify-content: center; /*横方向*/
  flex-wrap: wrap;
}

@mixin fxb_s_b{
  display: flex;
  align-items: flex-start; /* 縦方向*/
  justify-content: space-between; /*横方向*/
  flex-wrap: wrap;
}

@mixin fxb_c_b{
  display: flex;
  align-items: center; /* 縦方向*/
  justify-content: space-between; /*横方向*/
  flex-wrap: wrap;
}

@mixin fxb_e_b{
  display: flex;
  align-items: flex-end; /* 縦方向*/
  justify-content: space-between; /*横方向*/
  flex-wrap: wrap;
}


