@charset "UTF-8";
@use "../utility/variable" as var;
//Quattrocento
@import url("https://fonts.googleapis.com/css2?family=Quattrocento:wght@400;700&display=swap");

* {
  //font-family: var.$fontMincho;
}
body {
  color: var.$baseColor;
  font-family: var.$fontMincho;
  font-weight: 400;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.6em;
}
a {
  img {
    opacity: 1;
    transition: opacity 0.4s;
  }
  &:hover {
    img {
      opacity: 0.8;
    }
  }
  &.opacity {
    opacity: 1;
    transition: opacity 0.4s;
    &:hover {
      opacity: 0.8;
    }
  }
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.center {
  text-align: center;
}
.flex {
  display: flex;
}
.f-wrap {
  flex-wrap: wrap;
}
.f-jc-sb {
  justify-content: space-between;
}
.f-jc-c {
  justify-content: center;
}
.f-jc-fe {
  justify-content: flex-end;
}
.f-ai-c {
  align-items: center;
}
.f-ai-fs {
  align-items: flex-start;
}
.f-ai-fe {
  align-items: flex-end;
}
.f-ac-c {
  align-content: center;
}
.base {
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 15px;
  @media only screen and (max-width: 767px) {
    padding: 0 2rem;
  }
}

@media only screen and (min-width: 768px) {
  .sp {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .pc {
    display: none !important;
  }
}

/* ----------------------------------------

Wedding garden

---------------------------------------- */

:root {
  @media only screen and (max-width: 767px) {
    font-size: calc(100vw / 37.5);
  }
}

/* 基本ボックス設定
------------------------------- */

.container {
  width: 100%;
  overflow: hidden;
}

.wrap__out,
.wrap__main {
  width: 100%;
  height: auto;
}

.wrap__in {
  width: 1200px;
  max-width: 100%;
  height: auto;
  margin: 0 auto;

  @media only screen and (max-width: 767px) {
    width: 29.5rem;
  }
}

/*テーブルブロック
------------------------------- */

.table_block {
  display: table;
  table-layout: fixed;
  width: 100%;

  > .table_block--cell {
    display: table-cell;
    width: 50%;
    text-align: center;
    vertical-align: middle;
  }

  &._sp_tate {
    @media only screen and (max-width: 767px) {
      display: block;
      table-layout: auto;

      > .table_block--cell {
        display: block;
        width: 100%;
      }
    }
  }
}

/* flex box
------------------------------- */
.flex_block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

/* 画像ブロック
------------------------------- */
.img_block {
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &::after {
    content: "";
    display: block;
    padding-top: 100%;
  }
}

/* object-fit
------------------------------- */

.fit_img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/* ラインマーカー
------------------------------- */
.linemk {
  background: linear-gradient(transparent 70%, #fccb0f 70%);
}

/* ----------------------------------------

共通部分

---------------------------------------- */

// .top {
//   padding-top: 108px;

//   @media only screen and (max-width: 767px) {
//     padding-top: 7.5rem;
//   }
// }

/* header
------------------------------- */

.header {
  background-color: #fff;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;

  &__in {
    @include var.fxb_st_s;
    width: 100%;
    height: 108px;
    margin: 0 auto;
    color: var.$colorChcl;

    @media only screen and (max-width: 767px) {
      height: 7.5rem;
    }
  }

  &__logo {
    display: block;
    width: 237px;
    position: relative;
    padding-top: 41px;

    &__img {
      display: block;
      width: 202px;
      margin-left: auto;
      margin-right: 0;
    }

    img {
      display: block;
      width: 100%;
    }

    @media only screen and (max-width: 767px) {
      flex-grow: 1;
      width: auto;
      padding-top: 2.6rem;
      padding-left: 1.98rem;
      position: relative;

      &__img {
        width: 13.75rem;
        margin-left: 0;
      }

      &__insta_sp {
        display: block;
        width: 1.2rem;
        height: auto;
        position: absolute;
        top: 50%;
        right: 2.7rem;
        margin-top: -0.6rem;
        img {
          display: block;
          width: 100%;
        }
      }
    }
  }

  &__ttl {
    font-family: var.$fontGothic;
    font-size: 11px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.1em;
    white-space: nowrap;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 !important;
    border-right: 0 !important;

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  &__nav {
    display: block;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 35px;

    @media only screen and (min-width: 768px) {
      flex-grow: 1;
    }

    @media only screen and (max-width: 767px) {
      position: absolute;
      top: 7.5rem;
      right: 0;
      width: 100%;
      height: calc(100vh - 7.5rem);
      padding: 0 !important;
      background-color: #fff;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .header__ttl {
        display: none !important;
      }

      &__inner {
        @include var.fxb_s_e;
        flex-direction: column-reverse;
        padding: 3rem 0 12rem;
      }
    }

    &__in {
      @include var.fxb_s_e;
      width: 100%;
      max-width: 910px;
      margin-left: auto;
      margin-right: 0;

      @media only screen and (min-width: 768px) {
        a {
          color: var.$colorChcl;
          letter-spacing: 0.09em;
          line-height: 1.2;
          display: block;
        }

        &._main {
          justify-content: space-between; /*横方向*/
          font-size: 13px;
          margin-top: 22px;

          > li {
            height: 43px;
            > a {
              height: 21px;
              //border-bottom: 1px solid var.$colorGr;
            }
          }
        }

        &._sub {
          position: relative;

          li {
            font-family: var.$fontGothic;
            font-size: 11px;
            padding: 0 0.8em;

            &:not(.header__ttl):not(._ico) {
              position: relative;

              &::before {
                content: "";
                display: block;
                width: 1px;
                height: 10px;
                background-color: var.$colorChcl;
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -5px;
              }
            }

            &.header__ttl + li {
              &::before {
                display: none !important;
              }
            }

            &._ico {
              @include var.fxb_c_s;
              gap: 8px;
              border-right: 0;
              padding-right: 4px;
              padding-left: 13px;

              .ico-insta {
                display: block;
                width: 12px;
              }

              .ico-fb {
                display: none;
              }
            }
          }
        }
      }

      @media only screen and (max-width: 767px) {
        display: block;
        width: 29.4rem;
        max-width: 100%;
        margin: 0 auto;

        > li {
          width: 100%;
          padding: 0 !important;
          border-right: 0 !important;

          > a {
            @include var.fxb_c_s;
            width: 100%;
            height: 5rem !important;
            border-bottom: 1px solid var.$colorBe;
            font-size: 1.3rem;
            font-weight: 400;
            line-height: 1.2;
            letter-spacing: 0.1em;
            color: var.$colorDbe;
            background-image: url("../../img/common/ico_oval_arrow_br_whB.svg");
            background-size: 2.1rem auto;
            background-position: right 0 center;
            background-repeat: no-repeat;
            background-color: #fff;
          }

          &._ico {
            @include var.fxb_s_s;
            width: 100%;
            padding: 4rem 0 !important;
            gap: 2rem !important;

            > a {
              display: block !important;
              width: auto !important;
              height: auto !important;
              background-image: none;
              border-bottom: 0 !important;

              > img {
                width: auto;
                height: 1.8rem;
              }

              &.ico-fb {
                display: block !important;
              }
            }
          }
        }
      }
    }
  }

  &__btn {
    display: block;
    width: 133px;
    height: 100%;
    background-color: var.$colorGr;
    color: #fff;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.05em;
    text-align: center;
    padding-top: 30px;
    cursor: pointer;

    @media only screen and (max-width: 767px) {
      display: block;
      width: 7.5rem;
      height: 100%;
      background-color: var.$colorGr;
      color: #fff;
      font-size: 1.1rem;
      line-height: 1.5;
      letter-spacing: 0.05em;
      text-align: center;
      padding-top: 2.06rem;
    }

    ._ico_mail {
      display: block;
      width: 18px;
      margin: 0 auto 9px;

      @media only screen and (max-width: 767px) {
        display: block;
        width: 1.75rem;
        margin: 0 auto 0.9rem;
      }
    }

    @media only screen and (min-width: 768px) {
      opacity: 1;
      transition: opacity 0.4s;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__menu-btn {
    display: none;
    width: 133px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.4s;

    &:hover {
      opacity: 0.8;
    }

    @media only screen and (max-width: 767px) {
      @include var.fxb;
      width: 7.1rem;
      height: 100%;
    }

    ._btn_menu {
      display: block;
      margin: 0 auto;
      width: 30px;

      @media only screen and (max-width: 767px) {
        width: 3rem;
      }
    }
  }
}

/*pull down menu*/

.header__in {
  @media only screen and (min-width: 768px) {
    position: relative;
  }
}

.pull-down-menu {
  @media (min-width: 1131px) {
    &__btn {
      display: none;
    }

    &__block {
      max-height: 0;
      overflow: hidden;
      width: 100vw;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 0;
      transition: max-height 0.4s;
    }

    &__inner {
      @include var.fxb;
      min-height: 77px;
      background-color: var.$colorLgl;
    }

    &__list {
      @include var.fxb_s_s;
      gap: 8px 32px;
      max-width: 90%;
      font-size: 13px;
      font-weight: 400;
      line-height: 1.2;
      letter-spacing: 0.05em;
      padding: 10px 0;

      &__item {
        a {
          @include var.fxb_c_s;
          min-height: 20px;
          background-image: url("../../img/common/ico_oval_arrow_gr_wh.svg");
          background-size: 17px auto;
          background-position: right 0 center;
          background-repeat: no-repeat;
          padding-right: 24px;
          color: var.$colorGr;
        }
      }

      &._brown {
        .pull-down-menu__list__item a {
          color: var.$colorDbe;
          background-image: url("../../img/common/ico_oval_arrow_br_wh.svg");
        }
      }
    }

    &:hover {
      > a {
        color: var.$colorGr;
        border-bottom: 1px solid var.$colorGr;
      }

      .pull-down-menu__block {
        max-height: 500px;
        z-index: 1;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    position: relative;

    > a {
      background-image: none !important;
    }

    &__list__item {
    }

    &__btn {
      display: block;
      width: 100%;
      height: 5rem;
      position: absolute;
      top: 0;
      right: 0;

      &::after {
        content: "";
        display: block;
        width: 2.1rem;
        height: 2.1rem;
        background-image: url("../../img/common/ico_oval_arrow_br_wh_down.svg");
        background-size: 100% auto;
        background-position: center center;
        background-repeat: no-repeat;
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -1.05rem;
        transition: 0.4s;
      }
    }

    &__list {
      padding: 1rem 0 2rem;

      &__item {
        > a {
          @include var.fxb_c_s;
          width: 100%;
          height: 4.4rem;
          font-size: 1.3rem;
          line-height: 1.2;
          letter-spacing: 0.05em;
          text-align: left;
          color: var.$colorGr;
          border-bottom: 1px solid #e0dcd0;
          background-image: url("../../img/common/ico_oval_arrow_gr_wh.svg");
          background-size: 1.7rem auto;
          background-position: right 0 center;
          background-repeat: no-repeat;
        }
      }

      &._brown {
        .pull-down-menu__list__item > a {
          background-image: url("../../img/common/ico_oval_arrow_br_wh.svg");
          color: var.$colorDbe;
        }
      }
    }
  }
}

/*相談予約 お問い合わせ drawer*/

.drawer-contact {
  width: 100%;
  height: calc(100vh - 108px);
  position: fixed;
  top: 108px;
  left: 0;
  background-color: var.$colorGr;
  color: #fff;

  @media only screen and (max-width: 767px) {
    width: 100%;
    height: calc(100vh - 7.5rem);
    top: 7.5rem;
    left: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__block {
    max-width: 1200px;
    width: 96%;
    height: 100%;
    margin: 0 auto;
    padding-top: 73px;
    padding-bottom: 10px;

    @media only screen and (max-width: 767px) {
      max-width: 100%;
      width: 29.5rem;
      height: auto;
      padding-top: 5.7rem;
      padding-bottom: 5rem;
    }
  }

  &__inner {
    width: 100%;
    height: 100%;

    @media only screen and (min-width: 768px) {
      padding-bottom: 50px;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    > .btn-oval-arrow {
      margin: 0 auto 32px;
    }

    @media only screen and (max-width: 767px) {
      height: auto;
      padding-bottom: 12rem;

      > .btn-oval-arrow {
        width: 100%;
        margin: 0 auto 2rem;
      }
    }
  }

  &__ttl-large {
    font-family: "Quattrocento";
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.1em;
    text-align: center;
    margin-bottom: 34px;

    span {
      font-family: var.$fontMincho;
      display: block;
      font-size: 12px;
      font-weight: 400;
      line-height: 2;
      margin-top: 10px;
      letter-spacing: 0.05em;
    }

    @media only screen and (max-width: 767px) {
      font-size: 2rem;
      margin-bottom: 2.3rem;

      span {
        font-size: 1.2rem;
        margin-top: 1rem;
      }
    }
  }

  &__p {
    font-family: var.$fontMincho;
    font-size: 13px;
    line-height: 1.4;
    letter-spacing: 0.05em;
    text-align: center;
    a {
      display: block;
      font-family: "Quattrocento";
      font-size: 20px;
      font-weight: 400;
      line-height: 1.2;
      letter-spacing: 0.1em;
      color: #fff;
      margin-bottom: 2px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 1.3rem;

      a {
        font-size: 2rem;
        margin-bottom: 0.2rem;
      }
    }
  }

  &__ttl-mid {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: 0.05em;
    text-align: center;
    padding-bottom: 20px;
    margin-bottom: 29px;
    border-bottom: 1px solid #fff;

    @media (max-width: 1000px) {
      font-size: 14px;
    }

    @media only screen and (max-width: 767px) {
      @include var.fxb_c_s;
      height: 6.2rem;
      font-size: 1.6rem !important;
      text-align: left;
      padding-bottom: 0;
      margin-bottom: 0;

      + ._aco_block_sp {
        padding-top: 1.7rem;
      }
    }
  }

  &__list {
    margin-bottom: 35px;

    @media only screen and (min-width: 768px) {
      @include var.fxb_s_s;
      gap: 20px calc((33 / 1200) * 100%);
    }
    @media only screen and (max-width: 767px) {
      margin-bottom: 5.4rem;
    }

    &__item {
      width: calc((100% - 1px - ((calc((33 / 1200) * 100%)) * 3)) / 4);

      .btn-oval-arrow {
        width: 100%;
        margin-bottom: 8px;
      }

      @media only screen and (max-width: 767px) {
        width: 100%;

        .btn-oval-arrow {
          margin-bottom: 0;

          + .btn-oval-arrow {
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
}

.btn-oval-arrow {
  @include var.fxb;
  width: 275px;
  height: 60px;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.05em;
  text-align: center;
  color: var.$colorGr;
  background-color: #fff;
  background-image: url("../../img/common/ico_oval_arrow_gr.svg");
  background-size: 22px auto;
  background-position: right 15px center;
  background-repeat: no-repeat;

  @media only screen and (max-width: 767px) {
    width: 29.5rem;
    max-width: 100%;
    height: 6rem;
    font-size: 1.3rem;
    background-size: 2.2rem auto;
    background-position: right 2rem center;
  }
}

._aco-btn-sp {
  @media only screen and (max-width: 767px) {
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 2.2rem;
      aspect-ratio: 1 / 1;
      background-image: url("../../img/common/ico_oval_arrow_wh_dwn.svg");
      background-size: 100% auto;
      background-position: center center;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -1.2rem;
      transition: 0.3s;
    }

    &._active {
      &::after {
        transform: rotate(-180deg);
      }
    }
  }
}

._aco-block-sp {
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1300px) {
  .header {
    &__nav {
      padding-right: 15px;
    }
    &__logo {
      width: 217px;
    }

    &__btn,
    &__menu-btn {
      //width: 100px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1130px) {
  .header {
    &__logo {
      width: auto;
      flex-grow: 1;

      &__img {
        margin-left: 20px;
        margin-right: auto;
      }
    }

    /*menu*/
    &__nav {
      position: absolute;
      top: 108px;
      right: 0;
      width: 375px;
      height: calc(100vh - 108px);
      padding: 0 !important;
      background-color: #fff;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .header__ttl {
        display: none !important;
      }

      &__inner {
        @include var.fxb_s_e;
        flex-direction: column-reverse;
        padding: 30px 0;
      }
    }

    .header__nav__in {
      display: block;
      width: 294px;
      margin: 0 auto;

      > li {
        width: 100%;
        padding: 0 !important;
        border-right: 0 !important;
        height: auto;

        &::before {
          display: none !important;
        }

        > a {
          @include var.fxb_c_s;
          width: 100%;
          height: 50px !important;
          border-bottom: 1px solid var.$colorBe;
          font-size: 13px;
          font-weight: 400;
          line-height: 1.2;
          letter-spacing: 0.1em;
          color: var.$colorDbe;
          background-image: url("../../img/common/ico_oval_arrow_br_whB.svg");
          background-size: 21px auto;
          background-position: right 0 center;
          background-repeat: no-repeat;
          background-color: #fff;
        }

        &._ico {
          width: 100%;
          padding: 40px 0 !important;
          gap: 20px !important;

          > a {
            display: block !important;
            width: auto !important;
            height: auto !important;
            background-image: none;
            border-bottom: 0 !important;

            > img {
              width: auto;
              height: 19px;
            }

            &.ico-fb {
              display: block !important;
            }
          }
        }
      }
    }

    &__menu-btn {
      @include var.fxb;
      text-align: center;
    }

    .pull-down-menu {
      position: relative;

      &:hover {
        > a,
        .pull-down-menu__btn {
          opacity: 0.8;
        }
      }

      > a {
        background-image: none !important;
      }

      &__btn {
        display: block;
        width: 100%;
        height: 50px;
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 0;
        cursor: pointer;
        transition: opacity 0.4s;

        &::after {
          content: "";
          width: 21px;
          height: 21px;
          background-image: url("../../img/common/ico_oval_arrow_br_wh_down.svg");
          background-size: 100% auto;
          background-position: center center;
          background-repeat: no-repeat;
          position: absolute;
          top: 25px;
          right: 0;
          margin-top: -10.5px;
          transition: 0.4s;
        }
      }

      &__list {
        padding: 10px 0;

        &__item {
          > a {
            @include var.fxb_c_s;
            width: 100%;
            height: 44px;
            font-size: 13px;
            line-height: 1.2;
            letter-spacing: 0.05em;
            text-align: left;
            color: var.$colorGr;
            border-bottom: 1px solid #e0dcd0;
            background-image: url("../../img/common/ico_oval_arrow_gr_wh.svg");
            background-size: 17px auto;
            background-position: right 0 center;
            background-repeat: no-repeat;
          }
        }
        &._brown {
          .pull-down-menu__list__item > a {
            background-image: url("../../img/common/ico_oval_arrow_br_wh.svg");
            color: var.$colorDbe;
          }
        }
      }
    }
  }
}

/*drawer設定
------------------------------- */

.header {
  z-index: 100;
}

._drawer-contact-block {
  display: none;
}

.pull-down-menu {
  &__block {
    @media (max-width: 1130px) {
      display: none;
    }
  }

  &__btn {
    transition: 0.3s;

    &._active {
      &::after {
        transform: rotate(-180deg);
      }
    }
  }
}

.header__nav {
  @media (max-width: 1130px) {
    display: none;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.bg-overlay {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: none;
}

._overflow-hidden {
  overflow: hidden;
}

/* Footer
------------------------------- */

.footer {
  background-color: var.$colorDbe;
  padding: 72px 0 0;

  @media only screen and (max-width: 767px) {
    padding: 8.44rem 0 0;
  }

  &__logo {
    display: block;
    width: 202px;

    img {
      display: block;
      width: 100%;
    }

    @media only screen and (max-width: 767px) {
      width: 20.2rem;
    }
  }

  &__sns {
    @include var.fxb_s_s;
    gap: 15px;
    margin-top: 26px;

    &__ico {
      display: block;
      width: auto;
      height: 16px;

      img {
        display: block;
        width: 100%;
      }
    }

    @media only screen and (max-width: 767px) {
      gap: 2rem;
      margin-top: 3.8rem;

      &__ico {
        height: 1.9rem;

        img {
          width: auto;
          height: 100%;
        }
      }
    }
  }

  &__in {
    width: 96%;
    margin: 0 auto;

    @media only screen and (min-width: 768px) {
      @include var.fxb_s_b;
      flex-wrap: nowrap;
      gap: 30px;
      max-width: 1200px;
    }

    @media only screen and (max-width: 767px) {
      width: 29.4rem;
    }
  }

  &__cell {
    &._blockA {
      width: 202px;
      padding: 4px 0 0;

      @media only screen and (max-width: 767px) {
        width: 100%;
        padding: 0;
        margin-bottom: 3.7rem;
      }
    }

    &._blockB {
      width: 881px;

      @media only screen and (min-width: 768px) {
        @include var.fxb_s_b;
        gap: 20px;
        max-width: calc(100% - 202px);

        @media only screen and (max-width: 1000px) {
          @include var.fxb_s_s;
          gap: 30px 30px;
        }
      }

      @media only screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }

  &__nav {
    @media only screen and (min-width: 768px) and (max-width: 1000px) {
      width: 150px;
    }

    @media only screen and (max-width: 767px) {
      margin-bottom: 3.6rem;
    }

    &__item {
      margin-bottom: 17px;
      a {
        display: block;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.4;
        letter-spacing: 0.1em;
        text-align: left;
        color: #fff;
        white-space: nowrap;
      }

      @media only screen and (max-width: 767px) {
        margin-bottom: 1.7rem;
        a {
          font-size: 1.3rem;
        }
      }
    }

    &__btm {
      @include var.fxb_s_c;
      gap: 10px 0;
      width: 100%;
      margin-top: 54px;
      margin-bottom: 28px;

      @media only screen and (max-width: 767px) {
        @include var.fxb_s_s;
        gap: 1rem 0;
        width: 31.9rem;
        margin: 6.1rem auto 2.9rem;
      }

      &__item {
        display: block;
        position: relative;

        &::after {
          content: "";
          display: block;
          width: 1px;
          height: 10px;
          background-color: #fff;
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -5px;

          @media only screen and (max-width: 767px) {
            height: 1rem;
            margin-top: -0.5rem;
          }
        }

        &:last-child {
          &::after {
            display: none;
          }
        }

        a {
          display: block;
          font-size: 12px;
          line-height: 1.2;
          letter-spacing: 0.1em;
          color: #fff;
          padding: 0 0.95em;
          font-feature-settings: "palt";

          @media only screen and (max-width: 767px) {
            font-family: var.$fontGothic;
            font-size: 1.1rem;
            line-height: 1;
            letter-spacing: 0;
            padding: 0 0.95em;
          }
        }
      }
    }
  }
}

.copyright-sp {
  @media only screen and (max-width: 767px) {
    font-family: "Quattrocento", serif;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.05em;
    text-align: center;
    color: #fff;
    margin-bottom: 2.9em;
  }
}

.copyright {
  background-color: var.$colorLgl;
  font-family: "Quattrocento", serif;
  font-size: 12px;
  line-height: 1.2;
  text-align: center;
  color: var.$colorDbe;
  padding: 23px 0;

  @media only screen and (max-width: 767px) {
    font-size: 1.1rem;
    padding: 2.3rem 0 7rem;
  }
}

/* etc
------------------------------- */

.ttl-large-en {
  font-family: var.$fontQuat;
  font-size: 35px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: var.$colorDbe;
  text-align: center;
  margin-bottom: 63px;
  &.sub-page {
    margin-bottom: 0;
    padding: 80px 0;
  }

  ._sub {
    display: block;
    font-size: 13px;
    font-family: var.$fontMincho;
    font-weight: 400;
    letter-spacing: 0.05em;
    margin-top: 5px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 2.8rem;
    margin-bottom: 4.7rem;
    &.sub-page {
      padding: 6rem 0;
    }

    ._sub {
      font-size: 1.3rem;
      margin-top: 0;
    }
  }
}

/* btn
------------------------------- */

.btn-search {
  @include var.fxb;
  width: 218px;
  height: 40px;
  font-family: var.$fontGothic;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.1em;
  text-align: center;
  color: #fff;
  padding-left: 39px;
  border: 1px solid #fff;
  position: relative;
  cursor: pointer;
  transition: 0.4s;

  &::before {
    content: "";
    display: block;
    width: 39px;
    height: 38px;
    background-color: #fff;
    background-image: url("../../img/top/ico_search_br.svg");
    background-size: 15px auto;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media only screen and (min-width: 768px) {
    &:hover {
      background-color: rgba(255, 255, 255, 0.4);
      opacity: 0.8;
    }
  }

  @media only screen and (max-width: 767px) {
    width: 21.8rem;
    height: 4rem;
    font-size: 1.3rem;
    padding-left: 3.9rem;

    &::before {
      width: 3.9rem;
      height: calc(4rem - 2px);
      background-size: 1.5rem auto;
    }
  }
}

.btn-anchor {
  display: block;
  width: 7px;
  height: 71px;
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);

  &::before {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateY(0) translateX(-50%);
  }

  &::after {
    content: "";
    display: block;
    width: 726px;
    aspect-ratio: 1 / 1;
    border-radius: 726px;
    background-color: rgba(255, 255, 255, 0.1);
    position: absolute;
    bottom: -360px;
    left: 50%;
    transition: 0.3s;
    transform: translateY(0) translateX(-50%);
    pointer-events: none;
  }

  i {
    display: block;
    width: 7px;
    height: 7px;
    border-radius: 10px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    animation:
      a 2.5s ease-in-out infinite,
      ahide 2.5s ease-out infinite;
  }

  @media only screen and (max-width: 767px) {
    display: block;
    width: 0.7rem;
    height: 7.1rem;

    &::after {
      display: none;
    }

    i {
      width: 0.7rem;
      height: 0.7rem;
      border-radius: 10px;
      background-color: #fff;
      position: absolute;
    }
  }
}

@keyframes a {
  0% {
    bottom: 92%;
  }
  100% {
    bottom: 0%;
  }
}

@keyframes ahide {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  80% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}

.btn-more {
  @include var.fxb;
  width: 300px;
  max-width: 100%;
  height: 60px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.05em;
  color: #fff;
  text-align: center;
  background-color: var.$colorDbe;
  background-image: url("../../img/top/arrow_br_wh.svg");
  background-size: 23px auto;
  background-position: right 17px center;
  background-repeat: no-repeat;

  &._wh {
    color: var.$colorDbe;
    background-color: #fff;
    background-image: url("../../img/top/arrow_wh_br.svg");
  }

  &._gr {
    background-color: var.$colorGr;
    background-image: url("../../img/top/arrow_wh_gr.svg");
  }

  @media only screen and (max-width: 767px) {
    width: 29.5rem;
    height: 6rem;
    font-size: 1.3rem;
    background-size: 2.3rem auto;
    background-position: right 1.7rem center;
  }
}

/* slider
------------------------------- */

/*slide*/

.slide {
  width: auto;
  margin-left: -10px;
  margin-right: -10px;

  &__in {
    width: 280px !important;
    padding: 0 10px;
  }

  .slick-arrow {
    width: 40px;
    height: 100%;
    cursor: pointer;
    background-size: 23px auto;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;

    &.slick-prev {
      background-image: url("../../img/common/arrow_slideL.svg");
      background-position: 0 center;
      right: 100%;
    }

    &.slick-next {
      background-image: url("../../img/common/arrow_slideR.svg");
      background-position: right center;
      left: 100%;
    }
  }

  @media only screen and (max-width: 767px) {
    width: auto;
    margin-left: -1rem;
    margin-right: -1rem;

    &__in {
      width: 31.5rem !important;
      padding: 0 1rem;
    }

    &.js-slide-sp-off {
      .slide__in {
        margin-bottom: 3.3rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .slick-arrow {
      width: 4rem;
      height: 100%;
      cursor: pointer;
      background-size: 2.3rem auto;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;

      &.slick-prev {
        background-position: 0 center;
        right: 100%;
      }

      &.slick-next {
        background-position: right center;
        left: 100%;
      }
    }
  }
}

.slideB {
  width: auto;
  margin-left: calc((100px / 3) * -0.5);
  margin-right: calc((100px / 3) * -0.5);

  &__in {
    width: calc((1100px + calc(100px / 3)) / 4) !important;
    padding: 0 calc((100px / 3) * 0.5);
  }

  .slick-arrow {
    width: 34px;
    height: 100%;
    cursor: pointer;
    background-size: 25px auto;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;

    &.slick-prev {
      background-image: url("../../img/common/arrow_slideL.svg");
      background-position: 0 113px;
      right: 100%;
    }

    &.slick-next {
      background-image: url("../../img/common/arrow_slideR.svg");
      background-position: right 0 top 113px;
      left: 100%;
    }
  }

  .product {
    color: var.$baseColor;
  }

  .product__img {
    object-position: 50% 50%;
  }

  .product__meta {
    margin-top: 16px;
  }

  .product__meta__item {
    font-family: var.$fontMincho;
    padding: 0 9px;
    height: 21px;
  }

  .product__p {
    margin-bottom: 0;
  }

  @media only screen and (min-width: 768px) {
    .product__img {
      filter: grayscale(100%);
      transition: filter 0.4s;
    }

    a:hover .product__img {
      opacity: 1;
      filter: grayscale(0);
    }
  }

  @media only screen and (max-width: 767px) {
    width: auto;
    margin-left: -1.1rem;
    margin-right: -1.1rem;

    &__in {
      width: 27.2rem !important;
      padding: 0 1.1rem;
    }

    .slick-arrow {
      width: 2.5rem;
      height: 100%;
      cursor: pointer;
      background-size: 2.5rem auto;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      z-index: 1;

      &.slick-prev {
        background-image: url("../../img/common/arrow_slideL.svg");
        background-position: 0 12.3rem;
        left: 3.8rem;
        right: auto;
      }

      &.slick-next {
        background-image: url("../../img/common/arrow_slideR.svg");
        background-position: right 0 top 12.3rem;
        right: 3.8rem;
        left: auto;
      }
    }

    .product {
      color: var.$baseColor;
    }

    .product__img {
      height: auto;
      aspect-ratio: 1 / 1;
      object-position: 50% 50%;
    }

    .product__meta {
      margin-top: 1.6rem;
      margin-bottom: 0.5rem;
    }

    .product__meta__item {
      padding: 0 0.9rem;
      height: 2.1rem;
    }

    .product__p {
      font-size: 1.5rem;
      line-height: 3.2rem;
    }
  }
}

.product {
  display: block;
  width: 100%;

  &__img__link {
    display: block;
  }

  &__img {
    display: block;
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    object-position: 50% 100%;
  }

  &__meta {
    @include var.fxb_c_s;
    margin: 13px 0 10px;
    gap: 3px;

    &__item {
      @include var.fxb;
      font-family: var.$fontGothic;
      height: 24px;
      padding: 0 15px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: 0.05em;
      color: #fff;
      background-color: var.$colorGr;

      &._itemA {
        background-color: var.$colorDbe;
      }

      &._itemB {
        background-color: var.$colorBe;
      }
    }
  }

  &__wrap {
    position: relative;

    .product__meta {
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 0;
    }
  }

  &__ttl {
    font-size: 18px;
    line-height: 1.5;
    letter-spacing: 0.05em;
    margin-bottom: 12px;

    &-s {
      font-size: 15px;
      margin-top: 18px;
      margin-bottom: 7px;
    }
  }

  &__p {
    font-size: 13px;
    line-height: 23px;
    letter-spacing: 0.05em;
    max-height: 3.7em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin-bottom: 13px;
    margin-right: -0.5em;
  }

  .btn-more {
    width: 100%;
    height: 48px;
  }

  @media only screen and (max-width: 767px) {
    &__img {
      height: 20rem;
      object-position: 50% 50%;
    }

    &__meta {
      margin: 1.1rem 0 1rem;
      gap: 0.3rem;

      &__item {
        @include var.fxb;
        height: 2.4rem;
        padding: 0 1.5rem;
        font-size: 1.2rem;

        &._itemA {
        }

        &._itemB {
        }
      }
    }

    &__wrap {
      .product__meta {
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 0;
      }
    }

    &__ttl {
      font-size: 1.8rem;
      margin-bottom: 0.6rem;

      &-s {
        font-size: 1.5rem;
        margin-top: 1.8rem;
        margin-bottom: 0.7rem;
      }
    }

    &__p {
      font-size: 1.3rem;
      line-height: 2.3rem;
      margin-bottom: 1.3rem;
    }

    .btn-more {
      height: 4.8rem;
    }
  }
}

/*Bridal Fair
------------------------------- */

.bridal-fair {
  // padding: 6px 0 100px;
  padding: 80px 0 120px;
  border-top: 1px solid #c9c8c0;
  // border-bottom: 1px solid #e6e6e6;
  background-color: #fff;
  position: relative;

  .ttl-large-en {
    width: 100%;
    // transform: translateY(-32px);
  }

  > .btn-more {
    margin: 57px auto 0;
  }

  &__in {
    width: 1100px;
    max-width: 85%;
  }

  .product__img {
    object-position: 50% 50%;
  }

  @media only screen and (max-width: 767px) {
    padding: 6rem 0 9rem;
    // padding: 0.2rem 0 4rem;
    // border-bottom: 1px solid #e6e6e6;
    background-color: #fff;
    position: relative;
    // overflow: hidden;
    .ttl-large-en {
      width: 100%;
      // transform: translateY(-2.9rem);
      margin-bottom: 3rem;
    }

    > .btn-more {
      display: none;
    }

    &__in {
      width: 100%;
      max-width: none;
    }

    .slide {
      width: auto;

      &__in {
        width: 27.8rem !important;
        padding: 0 0.9rem;
      }

      .slick-arrow {
        width: 2.5rem;
        height: 100%;
        cursor: pointer;
        background-size: 2.5rem auto;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        z-index: 1;

        &.slick-prev {
          background-image: url("../../img/common/arrow_slideL.svg");
          background-position: 0 12.3rem;
          left: 3.8rem;
          right: auto;
        }

        &.slick-next {
          background-image: url("../../img/common/arrow_slideR.svg");
          background-position: right 0 top 12.3rem;
          right: 3.8rem;
          left: auto;
        }
      }
    }

    .product__img {
      height: auto;
      aspect-ratio: 1 / 1;
      object-position: 50% 50%;
    }
  }
}

/* breadcrumb
------------------------------- */

.breadcrumb {
  @include var.fxb_c_s;
  height: 45px;
  background-color: var.$colorLgl;

  ol {
    display: block;
    width: 1200px;
    max-width: 96%;
    margin: 0 auto;
    font-family: var.$fontGothic;
    font-size: 11px;
    line-height: 1.2;
    color: var.$colorDbe;

    li {
      position: relative;
      display: inline-block;
      margin-right: 15px;
      &:after {
        content: ">";
        font-family: var.$fontGothic;
        font-size: 11px;
        line-height: 1.2;
        color: var.$colorDbe;
        position: absolute;
        right: -14px;
        top: -1px;
      }
      &:last-child {
        margin-right: 0;
        &:after {
          display: none;
        }
      }
    }

    a {
      color: var.$colorDbe;
    }
  }

  @media only screen and (max-width: 767px) {
    height: 5.4rem;

    ol {
      width: 33.5rem;
      font-size: 1.1rem;
    }
  }
}

/* 会場を探すモーダル
------------------------------- */

.bnr_find_hall {
  @include var.fxb;
  width: 270px;
  height: 60px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.05em;
  text-align: center;
  color: #fff;
  background-image: url("../../img/common/ico_search.svg");
  background-color: var.$colorDbe;
  background-size: 15px auto;
  background-position: 20px center;
  background-repeat: no-repeat;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    opacity: 1;
    &:hover {
      transition: opacity 0.4s;
      opacity: 0.8;
    }
  }

  @media only screen and (max-width: 767px) {
    width: 36.5rem;
    height: 4rem;
    font-size: 1.2rem;
    background-size: 1.5rem auto;
    background-position: 1.85rem center;
    bottom: 0.5rem;
    right: 0.5rem;
  }
}

.find-hall {
  @include var.fxb;
  width: 100%;
  height: 100vh;
  background-color: rgba(155, 146, 119, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: none;

  &._flex {
    display: flex !important;
  }

  &__btn {
    position: absolute;
    top: 32px;
    right: 13px;
    cursor: pointer;

    img {
      display: block;
      width: 38px;
      height: auto;
    }
  }

  &__in {
    width: 1300px;
    max-width: 96%;
    max-height: 96%;
    margin: 0 auto;
    position: relative;
  }

  &__inner {
    @include var.fxb_s_c;
    width: 100%;
    max-height: 88vh;
    border-top: 62px solid #fff;
    border-bottom: 62px solid #fff;
    background-color: #fff;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    　 &::-webkit-scrollbar {
      display: none;
    }
  }

  &__block {
    @include var.fxb_s_c;
    gap: 2rem;
    width: 1100px;
    max-width: 86%;
    margin: 0 auto;

    .product {
      width: 250px;
    }
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    height: 100vh;
    padding-top: 2rem;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    &__btn {
      top: 2.1rem;
      right: 1.1rem;
      cursor: pointer;

      img {
        width: 3.8rem;
        height: auto;
      }

      &__btm {
        position: absolute;
        bottom: 2.5rem;
        left: 50%;
        margin-left: -5rem;

        img {
          display: block;
          width: 10rem;
          height: auto;
        }
      }
    }

    &__in {
      width: 33.5rem;
      max-width: none;
      max-height: none;
      margin-bottom: 12rem;
    }

    &__inner {
      width: 100%;
      height: auto;
      max-height: none;
      border-top: 9rem solid #fff;
      border-bottom: 9rem solid #fff;
      overflow-y: hidden;
    }

    &__block {
      @include var.fxb_s_c;
      gap: 2rem;
      width: 26rem;
      max-width: 86%;

      .product {
        width: 100%;
      }
    }
  }
}

.nav__box {
  display: flex;
  flex-wrap: wrap;
  gap: 0.7rem 0.5%;
  @media only screen and (max-width: 767px) {
    gap: 0.7rem 0;
    justify-content: space-between;
  }
  li {
    width: 16.25%;
    @media only screen and (max-width: 767px) {
      width: 49%;
      &.all {
        width: 100%;
      }
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      color: var.$colorDbe;
      background: var.$colorLgl;
      letter-spacing: 0.1em;
      text-align: center;
      transition: all 0.4s;
      height: 42px;
      @media only screen and (max-width: 767px) {
        font-size: 1.2rem;
        height: 4rem;
      }
      &:hover {
        color: #fff;
        background: var.$colorDbe !important;
      }
      &.active {
        color: #fff;
        background: var.$colorDbe !important;
      }
    }
  }
  &.white {
    li {
      a {
        background: #fff;
      }
    }
  }
  &.gold {
    li {
      a {
        background: #DCD9D2;
      }
    }
  }
  @media only screen and (min-width: 767px) {
    &.row05 {
      li {
        width: 19.5%;
      }
    }
  }
}

/* 相談カウンター
------------------------------- */

.counter {
  width: 100%;
  background-color: var.$colorLgl;
  padding: 93px 0 0;
  @media only screen and (max-width: 767px) {
    padding-top: 7.8rem;
  }

  .ttl-large-en {
    font-size: 20px;
    color: var.$colorDbe;
    margin-bottom: 65px;

    ._sub {
      margin-top: 10px;
    }

    @media only screen and (max-width: 767px) {
      font-size: 2rem;
      margin-bottom: 5.8rem;

      ._sub {
        margin-top: 0.6rem;
      }
    }
  }

  .btn-more {
    margin: 22px auto 37px;
    position: relative;
    z-index: 1;
    @media only screen and (max-width: 767px) {
      width: 100%;
      margin-top: 3.7rem;
      margin-bottom: 4rem;
    }
  }

  &__in {
    background-image: url(../../img/common/bg_counseling.jpg.webp);
    background-size: cover;
    background-position: center 0;
    background-repeat: no-repeat;
    padding: 89px 20px 75px;
    position: relative;
    overflow: hidden;

    .no-webp & {
      background-image: url(../../img/common/bg_counseling.jpg);
    }

    &::before {
      content: "";
      display: block;
      width: 726px;
      aspect-ratio: 1 / 1;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateY(-44%) translateX(-50%);
      z-index: 0;
    }

    @media only screen and (max-width: 767px) {
      width: 100%;
      max-width: 100%;
      background-image: url(../../img/common/bg_counseling_sp.jpg.webp);
      padding: 7.7rem 2rem 9.8rem;

      .no-webp & {
        background-image: url(../../img/common/bg_counseling_sp.jpg);
      }

      &::before {
        width: 34.9rem;
      }
    }
  }

  &__ttl {
    font-family: var.$fontQuat;
    font-size: 18px;
    letter-spacing: 1.6;
    letter-spacing: 0.1em;
    color: #fff;
    text-align: center;
    position: relative;
    z-index: 1;
    @media only screen and (max-width: 767px) {
      font-size: 1.8rem;
    }
  }

  &__ttl-sub {
    font-size: 13px;
    letter-spacing: 1.6;
    letter-spacing: 1.6;
    letter-spacing: 0.05em;
    color: #fff;
    text-align: center;
    position: relative;
    z-index: 1;
    @media only screen and (max-width: 767px) {
      flex: 1.3rem;
    }
  }

  &__list {
    @include var.fxb_s_c;
    gap: 15px;
    width: 100%;
    max-width: 1240px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto 38px;
    position: relative;
    z-index: 1;
    @media only screen and (max-width: 767px) {
      gap: 2rem;
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 3.5rem;
    }
  }

  &__item {
    width: calc((100% - 1px - (15px * 3)) / 4);
    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    .btn-oval-arrow {
      width: 100%;
    }
  }

  .tel-link {
    position: relative;
    z-index: 1;

    &__time {
      position: relative;
      z-index: 1;
    }
  }

  .btm-nav {
    background-image: url(../../img/common/bg_counseling.jpg.webp);
    background-size: cover;
    background-position: center 0;
    background-repeat: no-repeat;
    padding: 74px 20px 54px;
    position: relative;
    overflow: hidden;
    @media only screen and (max-width: 767px) {
      width: 100%;
      max-width: 100%;
      background-image: url(../../img/common/bg_counseling_sp.jpg.webp);
      background-color: #05090a;
      background-size: 100% auto;
      padding: 3.6rem 2rem 4.9rem;
    }

    .no-webp & {
      background-image: url(../../img/common/bg_counseling.jpg);
      @media only screen and (max-width: 767px) {
        background-image: url(../../img/common/bg_counseling_sp.jpg);
      }
    }

    &::before {
      content: "";
      display: block;
      width: 726px;
      aspect-ratio: 1 / 1;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateY(-44%) translateX(-50%);
      z-index: 0;
      @media only screen and (max-width: 767px) {
        width: 34.9rem;
      }
    }

    &_in {
      border-bottom: 1px solid #fff;
      position: relative;
      z-index: 1;
      @media only screen and (max-width: 767px) {
        border-bottom: 0;
      }
    }

    .drawer-contact__list {
      margin-bottom: 50px;
      @media only screen and (max-width: 767px) {
        margin-bottom: 4.1rem;
      }
    }

    .drawer-contact__ttl-mid {
      color: #fff;
    }

    .btn-more {
      &._wh {
        width: 275px;
        color: var.$colorGr;
        background-image: url(../../img/common/ico_oval_arrow_gr.svg);
        margin-top: 44px;
        margin-bottom: 28px;
        @media only screen and (max-width: 767px) {
          width: 29.5rem;
          margin-top: 4.1rem;
          margin-bottom: 2.1rem;
        }
      }
    }

    .tel-link {
      font-size: 28px;
      @media only screen and (max-width: 767px) {
        font-size: 2.2rem;
      }
    }

    .tel-link__time {
      margin-top: 6px;
      @media only screen and (max-width: 767px) {
        margin-top: 0;
      }
    }
  }
}

.tel-link {
  font-family: var.$fontQuat;
  font-size: 30px;
  line-height: 1.4;
  letter-spacing: 0.1em;
  color: #fff;
  text-align: center;
  @media only screen and (max-width: 767px) {
    font-size: 2.8rem;
  }

  a {
    color: #fff;
  }

  &__time {
    font-size: 13px;
    line-height: 1.4;
    letter-spacing: 0.05em;
    color: #fff;
    text-align: center;
    margin-top: 1px;
    @media only screen and (max-width: 767px) {
      font-size: 1.3rem;
      margin-top: 0.1rem;
    }
  }
}

/* ブライダルフェア おすすめのフェア
------------------------------- */

.bridal-fair-recommend {
  padding: 85px 0 74px;
  background-color: #fff;
  overflow: hidden;
  @media only screen and (max-width: 767px) {
    padding: 6.7rem 0 7.2rem;
  }

  .ttl-large-en {
    margin-bottom: 33px;
    @media only screen and (max-width: 767px) {
      margin-bottom: 3.3rem;
    }
  }

  .btn-more {
    margin: 53px auto 0;
    @media only screen and (max-width: 767px) {
      margin: 4.1rem auto 0;
      background-image: none;
    }
  }

  &__ttl {
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: 0.05em;
    text-align: center;
    color: var.$colorChcl;
    margin-bottom: 28px;
    @media only screen and (max-width: 767px) {
      font-size: 1.5rem;
      margin-bottom: 2.4rem;
    }
  }

  &__slide {
    width: 100%;

    .slick-arrow {
      width: 36px;
      height: 100%;
      background-size: 23px auto;
      background-position: center center;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      z-index: 1;
      cursor: pointer;
      @media only screen and (max-width: 767px) {
        width: 3.6rem;
        background-size: 2.5rem auto;
      }

      &.slick-prev {
        background-image: url("../../img/common/ico_oval_arrow_left_br.svg");
        left: 50%;
        margin-left: calc((724px / -2) - 36px);
        @media only screen and (max-width: 767px) {
          left: 0;
          margin-left: 0;
        }
      }

      &.slick-next {
        background-image: url("../../img/common/ico_oval_arrow_right_br.svg");
        right: 50%;
        margin-right: calc((724px / -2) - 36px);
        @media only screen and (max-width: 767px) {
          right: 0;
          margin-right: 0;
        }
      }
    }

    &__in {
      display: -webkit-flex !important;
      display: flex !important;
      align-items: flex-start; /* 縦方向*/
      justify-content: center; /*横方向*/
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      width: 724px !important;
      background-color: #fff;
      margin: 0 18px;
      @media only screen and (max-width: 767px) {
        display: block !important;
        width: 29.5rem !important;
        margin: 0 0.9rem;
      }
    }

    &__img {
      width: 260px;
      aspect-ratio: 1 / 1;
      @media only screen and (max-width: 767px) {
        width: 100%;
      }

      picture {
        display: block;
        width: 100%;
        height: 100%;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__text {
      width: calc(100% - 260px);
      min-height: 260px;
      border: 1px solid #bfbfbf;
      border-left: 0;
      padding: 33px 36px 15px 40px;
      @media only screen and (max-width: 767px) {
        width: 100%;
        min-height: 0;
        border-left: 1px solid #bfbfbf;
        border-top: 0;
        padding: 2.5rem 2rem 2.5rem;
      }
    }

    &__ttl {
      font-size: 16px;
      line-height: 1.7;
      letter-spacing: 0.05em;
      color: var.$colorGr;
      text-align: left;
      margin-bottom: 6px;
      @media only screen and (max-width: 767px) {
        font-size: 1.6rem;
        line-height: 1.5;
        margin-bottom: 0.9rem;
      }
    }

    &__desc {
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: 0.05em;
      color: var.$colorChcl;
      text-align: left;
      margin-bottom: 11px;
      @media only screen and (max-width: 767px) {
        min-height: 3.9rem;
        font-size: 1.4rem;
        margin-bottom: 0.5rem;
      }
    }

    &__caption {
      font-size: 13px;
      line-height: calc(24 / 13);
      letter-spacing: 0.05em;
      text-align: left;
      margin-bottom: 14px;
      @media only screen and (max-width: 767px) {
        font-size: 1.3rem;
        margin-bottom: 1.4rem;
      }
    }

    &__btn {
      @include var.fxb;
      width: 170px;
      height: 40px;
      font-size: 13px;
      line-height: 1.2;
      letter-spacing: 0.05em;
      color: #fff;
      background-color: var.$colorGr;
      @media only screen and (max-width: 767px) {
        width: 100%;
        height: 4rem;
        font-size: 1.3rem;
      }
    }
  }
}

/* ウェディングプラン
------------------------------- */

.wedding-plan {
  padding: 67px 0 108px;
  border-top: 1px solid #c9c8c0;
  border-bottom: 1px solid #c9c8c0;
  overflow: hidden;
  @media only screen and (max-width: 767px) {
    padding: 5.9rem 0 9.7rem;
  }

  .ttl-large-en {
    margin-bottom: 43px;
    @media only screen and (max-width: 767px) {
      margin-bottom: 3.8rem;
    }
  }

  .btn-more {
    margin: 44px auto 0;
    @media only screen and (max-width: 767px) {
      margin: 4.4rem auto 0;
    }
  }

  &__slide {
    max-width: 1100px;
    margin: 0 auto;
    @media only screen and (max-width: 767px) {
      margin: 0 -2rem;
    }

    .slick-arrow {
      width: 50px;
      height: 100%;
      background-size: 25px auto;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      z-index: 1;
      cursor: pointer;
      @media only screen and (max-width: 767px) {
        width: 5rem;
        background-size: 2.5rem auto;
      }

      &.slick-prev {
        background-image: url("../../img/common/ico_oval_arrow_left_br.svg");
        background-position: 0 168px;
        left: 0;
        transform: translateX(-100%);
        @media only screen and (max-width: 1200px) {
          transform: translateX(-15px);
          @media only screen and (max-width: 767px) {
            background-position: right 0.2rem top 16.8rem;
            transform: translateX(0);
          }
        }
      }

      &.slick-next {
        background-image: url("../../img/common/ico_oval_arrow_right_br.svg");
        background-position: right 0 top 168px;
        right: 0;
        transform: translateX(100%);
        @media only screen and (max-width: 1200px) {
          transform: translateX(15px);
          @media only screen and (max-width: 767px) {
            background-position: left 0.2rem top 16.8rem;
            transform: translateX(0);
          }
        }
      }
    }

    &__in {
      width: 260px !important;
      margin: 0 20px 0 0;
      @media only screen and (max-width: 767px) {
        width: 25rem !important;
        margin: 0 1rem 0;
      }
    }

    &__img {
      width: 100%;
      aspect-ratio: 1 / 1;

      a,
      picture {
        display: block;
        width: 100%;
        height: 100%;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__text {
      font-size: 14px;
      line-height: calc(25 / 14);
      position: relative;
      color: var.$colorGr;
      @media only screen and (max-width: 767px) {
        font-size: 1.4rem;
      }
    }

    &__meta {
      @include var.fxb_st_s;
      gap: 5px;
      padding: 13px 0 23px;
      @media only screen and (max-width: 767px) {
        padding: 1.1rem 0 1.3rem;
      }
    }

    &__chip {
      @include var.fxb;
      min-width: 78px;
      min-height: 24px;
      font-family: var.$fontGothic;
      font-size: 12px;
      line-height: 1.1;
      letter-spacing: 0.05em;
      color: #fff;
      background-color: var.$colorGr;
      padding: 3px 10px;
      @media only screen and (max-width: 767px) {
        min-width: 7.8rem;
        min-height: 2.4rem;
        font-size: 1.2rem;
        padding: 0.3rem 1rem;
      }
      &._itemA {
        background-color: var.$colorGr;
      }
      &._itemB {
        background-color: var.$colorBe;
      }
    }

    &__ttl {
      letter-spacing: 0.03em;
      @media only screen and (max-width: 767px) {
        letter-spacing: 0.05em;
        line-height: 1.7;
      }

      a {
        display: block;
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        color: inherit;
      }
    }

    &__desc {
      letter-spacing: 0.05em;
      line-height: inherit;
    }

    &__add-info {
      letter-spacing: 0.05em;
      line-height: inherit;
    }

    &__btn {
      @include var.fxb_c_s;
      width: 100%;
      min-height: 36px;
      font-size: 16px;
      line-height: 1.2;
      letter-spacing: 0.05em;
      color: var.$colorGr;
      background-color: #f0efe9;
      padding: 4px 18px;
      margin-top: 9px;
      @media only screen and (max-width: 767px) {
        min-height: 3.6rem;
        font-size: 1.6rem;
        padding: 0.4rem 1.8rem;
        margin-top: 0.5rem;
      }
    }
  }
}

/* 2カラム
------------------------------- */

.column-2 {
  @include var.fxb_st_b;
  max-width: 1130px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  @media only screen and (max-width: 767px) {
    flex-direction: column-reverse;
    padding-left: 0;
    padding-right: 0;
  }

  &__main {
    width: 820px;
    max-width: calc(100% - 190px - 15px);
    @media only screen and (max-width: 767px) {
      max-width: 100%;
    }
  }

  &__side {
    width: 190px;
    max-width: 100%;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }
}

/* modal
------------------------------- */

.js-img-modal {
  cursor: pointer;
  &_btn {
    transition: opacity 0.4s;

    &:hover {
      opacity: 0.8;
    }
  }
}

.img__modal {
  &__bg {
    display: none;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3000;
  }
  &__block {
    display: none;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3000;
    @media only screen and (max-width: 767px) {
      width: 100vw;
    }
  }

  &__wrap {
    @include var.fxb;
    width: 100%;
    height: 100%;
    padding: 0 50px;
    @media only screen and (max-width: 767px) {
      padding: 0 2rem;
    }
  }

  &__inner {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    @media only screen and (max-width: 767px) {
      padding: 1rem;
      border-radius: 1rem;
    }

    picture {
      display: block;
      width: 100%;
      height: 100%;
    }

    img {
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 80vh;
    }
  }

  &__close {
    display: block;
    width: 30px;
    aspect-ratio: 1 / 1;
    border-radius: 100%;
    border: 3px solid #fff;
    background-color: var.$colorDbe;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-50%) translateX(50%);
    cursor: pointer;
    @media only screen and (max-width: 767px) {
      width: 3rem;
      border: 0.3rem solid #fff;
    }

    &::before {
      content: "";
      display: block;
      width: 16px;
      height: 1px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%) rotate(45deg);
      @media only screen and (max-width: 767px) {
        width: 1.6rem;
      }
    }

    &::after {
      content: "";
      display: block;
      width: 16px;
      height: 1px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%) rotate(-45deg);
      @media only screen and (max-width: 767px) {
        width: 1.6rem;
      }
    }
  }
}

/* カード型アイテム共通デザイン ▼▼ */
.block {
  &__cover {
    display: flex;
    gap: 0 8%;
    align-items: flex-start;
    @media only screen and (max-width: 767px) {
      flex-wrap: wrap;
      gap: 10rem 0;
    }
  }

  &__contents {
    width: 68.7%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 58px 0;
    @media only screen and (max-width: 767px) {
      width: 100%;
      flex-wrap: wrap;
      gap: 3.4rem 0;
    }
  }

  &__main {
    display: flex;
    flex-wrap: wrap;
    gap: 30px 4%;
    @media only screen and (max-width: 767px) {
      gap: 2.6rem 0;
    }
  }

  &__item {
    width: 48%;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
    .img__cover {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }

  &__desc {
    background: var.$colorLgl;
    padding: 25.3px 25px 29.7px;
    @media only screen and (max-width: 767px) {
      padding: {
        top: 1.9rem;
        right: 2.1rem;
        bottom: 3rem;
        left: 2.1rem;
      }
    }
  }
  &__flex {
    display: flex;
    align-items: center;
    gap: 0 10px;
    margin: 0 0 14px;
  }

  &__arrow {
    position: relative;
    font-family: var.$fontQuat;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.1em;
    color: var.$colorDbe;
    @media only screen and (max-width: 767px) {
      font-size: 1.3rem;
      letter-spacing: 0.18em;
    }
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotate(45deg) skew(-1deg, -1deg);
      z-index: 0;
      width: 15px;
      height: 15px;
      border: solid 1.4px var.$colorDbe;
      @media only screen and (max-width: 767px) {
        border-width: 0.1rem;
      }
    }
    &.no__handle {
      color: #ccc;
      &:before {
        border-color: #ccc;
      }
    }
  }

  &__arrows {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: solid 1px #d9d9d9;
    padding: 20px 0 0;
    @media only screen and (max-width: 767px) {
      padding: {
        top: 1.3rem;
      }
    }
    &__box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: {
        bottom: 30px;
      }
      @media only screen and (max-width: 767px) {
        margin: {
          bottom: 0.6rem;
        }
      }
    }
    &__prev {
      margin: 0 0 0 12px;
      padding: 0 0 0 17px;
      @media only screen and (max-width: 767px) {
        margin: 0.5rem;
      }
      &::before {
        left: 0;
        border: {
          top: none;
          right: none;
        }
      }
    }
    &__next {
      margin: 0 12px 0 0;
      padding: 0 17px 0 0;
      @media only screen and (max-width: 767px) {
        margin: 0 0.5rem 0 0;
        padding: 0 1.7rem 0 0;
      }
      &::before {
        right: 0;
        border: {
          left: none;
          bottom: none;
        }
      }
    }
    .item__underbar__link {
      margin: 0 auto;
    }
  }
}

.item {
  &__tag {
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2em;
    letter-spacing: 0.05em;
    background: var.$colorDbe;
    color: #fff;
    margin: {
      right: 5px;
    }
    padding: 6px 20.8px;
    // white-space: nowrap;
    @media only screen and (max-width: 767px) {
      font-size: 1.2rem;
      margin: {
        right: 0.5rem;
      }
    }
  }

  &__date {
    font-family: var.$fontQuat;
    font-size: 14px;
    font-weight: 400;
    line-height: 2.1rem;
    letter-spacing: 0.1em;
    color: var.$colorDbe;
    @media only screen and (max-width: 767px) {
      font-size: 1.4rem;
    }
  }

  &__txt {
    font-size: 15px;
    font-weight: 500;
    line-height: 2em;
    letter-spacing: 0.05em;
    color: var.$baseColor;
    margin: 0 0 5px;
    @media only screen and (max-width: 767px) {
      font-size: 1.5rem;
    }
  }

  &__underbar__link {
    position: relative;
    display: block;
    width: 100%;
    max-width: 150px;
    font-size: 12px;
    font-weight: 500;
    line-height: 2.2em;
    letter-spacing: 0.05em;
    color: var.$colorDbe;
    margin: 0 0 0 auto;
    padding: 2.5px 0;
    border-bottom: solid 1px var.$colorDbe;
    @media only screen and (max-width: 767px) {
      max-width: 15rem;
      font-size: 1.2rem;
    }
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      z-index: 0;
      width: 21.6px;
      height: 21.6px;
      border-radius: 50%;
      border: solid 1.4px var.$colorDbe;
      box-sizing: border-box;
      @media only screen and (max-width: 767px) {
        width: 2.16rem;
        height: 2.16rem;
        border: solid 1px var.$colorDbe;
      }
    }
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 6%;
      transform: translateY(-50%) rotate(45deg) skew(5deg, 5deg);
      z-index: 0;
      display: flex;
      align-items: center;
      width: 4px;
      height: 4px;
      border: {
        top: solid 1.4px var.$colorDbe;
        right: solid 1.4px var.$colorDbe;
        left: none;
        bottom: none;
      }
      @media only screen and (max-width: 767px) {
        width: 0.4rem;
        height: 0.4rem;
        border: {
          top: solid 1px var.$colorDbe;
          right: solid 1px var.$colorDbe;
          left: none;
          bottom: none;
        }
      }
    }
  }
}

.sidebar {
  width: 24%;
  @media only screen and (max-width: 767px) {
    width: 100%;
    padding: 0 20px;
  }
  &__item {
    &:not(:last-child) {
      margin-bottom: 60px;
    }
  }
  &__ttl {
    font-family: var.$fontQuat;
    font-size: 18px;
    line-height: 1.5em;
    letter-spacing: 0.15em;
    color: var.$colorDbe;
    margin: -5px 0 25.5px;
    padding: 0 0 12px;
    border-bottom: solid 1px #e6e6e6;
    @media only screen and (max-width: 767px) {
      font-size: 1.8rem;
    }
  }
  ul {
    li {
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.05em;
      @media only screen and (max-width: 767px) {
        font-size: 1.3rem;
      }
      &:not(:last-child) {
        margin-bottom: 14px;
      }
    }
    a {
      color: var.$baseColor;
      opacity: 1;
      transition: all 0.4s;
      &:hover{
        opacity: 0.8;
      }
    }
  }
}

.information {
  .base {
    max-width: 1130px;
    @media only screen and (max-width: 767px) {
      padding: {
        right: 2rem;
        left: 2rem;
      }
    }
  }

  &__block {
    &__cover {
      display: flex;
      gap: 0 8%;
      @media only screen and (max-width: 767px) {
        flex-wrap: wrap;
        gap: 7.5rem;
      }
    }
    &__contents {
      width: 68.7%;
      @media only screen and (max-width: 767px) {
        width: 100%;
      }
    }
    &__main {
      display: flex;
      flex-wrap: wrap;
      gap: 0 4%;
      @media only screen and (max-width: 767px) {
        gap: 0;
      }
    }
  }
}

.pagenation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  @media only screen and (max-width: 767px) {
    margin-top: 4rem;
  }

  .page-numbers {
    font-size: 13px;
    color: var.$colorDbe;
    width: 21.6px;
    height: 21.6px;
    border-radius: 50%;
    border: solid 1px #fff;
    display: grid;
    place-items: center;
    text-align: center;
    transition: all 0.4s ease;
    margin: 0 2px;
    @media only screen and (max-width: 767px) {
      font-size: 1.3rem;
      width: 2.16rem;
      height: 2.16rem;
      margin: 0 0.2rem;
    }
    &.current {
      color: #fff;
      background: var.$colorDbe;
      border: solid 1px var.$colorDbe;
    }
  }

  .prev,
  .next {
    position: relative;
    width: 21.6px;
    height: 21.6px;
    border-radius: 50%;
    border: solid 1px var.$colorLgl;
    background: var.$colorLgl;
    color: #fff;
    @media only screen and (max-width: 767px) {
      width: 2.16rem;
      height: 2.16rem;
    }
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotate(45deg) skew(5deg, 5deg);
      z-index: 0;
      display: flex;
      align-items: center;
      width: 4px;
      height: 4px;
      @media only screen and (max-width: 767px) {
        width: 0.4rem;
        height: 0.4rem;
      }
    }
  }
  .prev {
    margin: 0 17px 0 0;
    @media only screen and (max-width: 767px) {
      margin: {
        right: 1.7rem;
      }
    }
    &::after {
      left: 40%;
      border: {
        top: none;
        right: none;
        left: solid 1.4px var.$colorDbe;
        bottom: solid 1.4px var.$colorDbe;
      }
      @media only screen and (max-width: 767px) {
        border: {
          top: none;
          right: none;
          left: solid 1px var.$colorDbe;
          bottom: solid 1px var.$colorDbe;
        }
      }
    }
  }
  .next {
    margin: 0 0 0 17px;
    &::after {
      right: 40%;
      border: {
        top: solid 1.4px var.$colorDbe;
        right: solid 1.4px var.$colorDbe;
        left: none;
        bottom: none;
      }
      @media only screen and (max-width: 767px) {
        border: {
          top: solid 1px var.$colorDbe;
          right: solid 1px var.$colorDbe;
          left: none;
          bottom: none;
        }
      }
    }
  }
}

.detail {
  &__ttl {
    font-size: 20px;
    letter-spacing: 0.1em;
    color: var.$colorDbe;
    margin: 0 0 13px;
    @media only screen and (max-width: 767px) {
      font-size: 1.6rem;
      line-height: 2em;
    }
    &.sub {
      margin: 0 0 27px;
    }
  }

  &__txt {
    font-size: 13px;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.05em;
    margin: 0 0 74px;
    @media only screen and (max-width: 767px) {
      font-size: 1.3rem;
      margin: {
        bottom: 5.5rem;
      }
    }
  }

  &__contents__cover {
    margin: 0 0 60px;

    .img__cover {
      &:not(:last-of-type) {
        margin: 0 0 30px;
        @media only screen and (max-width: 767px) {
          margin: {
            bottom: 2rem;
          }
        }
      }
    }

    &:last-child {
      margin: 0 0 100px;
    }
    &.contents03 {
      margin: 0 0 100px;
      @media only screen and (max-width: 767px) {
        margin: {
          bottom: 9.7rem;
        }
      }
      .detail__txt {
        margin: 0 0 55px;
      }
    }
    &.contents02,
    &.contents03 {
      @media only screen and (max-width: 767px) {
        .detail__ttl {
          font-size: 1.8rem;
          margin: {
            bottom: 2.3rem;
          }
        }
      }
    }
  }
}

//記事

.post__detail {
  padding: 0 0 100px;
  font-size: 14px;
  @media only screen and (max-width: 767px) {
    padding: 0 0 6rem;
    font-size: 1.4rem;
  }
  p {
    margin-bottom: 30px;
    @media only screen and (max-width: 767px) {
      margin-bottom: 3rem;
    }
  }
  a {
    color: var.$colorBe;
    text-decoration: underline;
  }
  h2 {
    font-size: 20px;
    color: var.$colorDbe;
    position: relative;
    margin-bottom: 15px;
    @media only screen and (max-width: 767px) {
      font-size: 1.8rem;
      margin-bottom: 1.5rem;
    }
  }
  h3 {
    font-size: 18px;
    padding-bottom: 4px;
    border-bottom: 2px solid #e5e6eb;
    margin-bottom: 15px;
    @media only screen and (max-width: 767px) {
      font-size: 1.6rem;
      padding-bottom: 0.4rem;
      margin-bottom: 1.5rem;
    }
  }
  ul {
    list-style: disc;
    padding-left: 15px;
    @media only screen and (max-width: 767px) {
      padding-left: 1.5rem;
    }
    li {
      line-height: 1.6em;
    }
  }
  ol {
    list-style: decimal;
    padding-left: 18px;
    @media only screen and (max-width: 767px) {
      padding-left: 1.8rem;
    }
    li {
      line-height: 1.6em;
    }
  }
}

// 下層用MV
.page-mv {
  @include var.fxb;
  align-content: center;
  width: 100%;
  height: 640px;
  padding-bottom: 10px;
  position: relative;

  @media only screen and (max-width: 767px) {
    height: 53rem;
    padding-bottom: 0;
  }

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.26);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__ttl {
    width: 100%;
    font-family: var.$fontQuat;
    font-size: 13px;
    height: 1.1;
    letter-spacing: 0.1em;
    text-align: center;
    color: #fff;
    margin-bottom: 15px;
    position: relative;
    z-index: 2;

    @media only screen and (max-width: 767px) {
      font-size: 1.3rem;
      margin-bottom: 1.5rem;
    }

    &.second__ttl {
      margin: 0;
    }
    &.three__ttl {
      font-size: 30px;
      @media only screen and (max-width: 767px) {
        font-size: 2.2rem;
      }
    }

    &.ttl02 {
      margin-bottom: 5px;
      @media only screen and (max-width: 767px) {
        margin-bottom: 0.5rem;
      }
    }
  }

  &__sub-ttl {
    width: 100%;
    font-family: var.$fontMincho;
    font-size: 30px;
    line-height: 1.2;
    letter-spacing: 0.05em;
    text-align: center;
    color: #fff;
    position: relative;
    z-index: 2;

    @media only screen and (max-width: 767px) {
      font-size: 2.2rem;
    }

    &.ttl02 {
      margin: 0 0 10px;
      @media only screen and (max-width: 767px) {
        margin: 0 0 1rem;
      }
    }
  }

  &__bg {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
}
